import {Button, FormControl, FormControlLabel, FormGroup, InputLabel, TextField, Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Select, MenuItem} from "@material-ui/core";
import React, {useState} from "react";
import useStyles from './styles';

export default function LongtermLoan(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const [term, setTerm] = useState(1);
    const [number, setNumber] = useState(0);

    const longLoan = useSelector(state => state.game.LongTermLoan);
    const L_term_loan = longLoan.reduce(function (accumulator, item){return accumulator + item.amount}, 0);

    const shortLoan = useSelector(state => state.game.ShortTermLoan);
    const S_term_loan = shortLoan.reduce(function (accumulator, item){return accumulator + item.amount}, 0);

    const canLoan = props.owner_equity * 3 - L_term_loan - S_term_loan;

    const quarter = useSelector(state => state.game.Quarter);

    const handleInputNumber = (event) => {
        setNumber(event.target.value === '' ? '' : Number(event.target.value).toFixed(0))
    };

    const handleInputTerm = (event) => {
        setTerm(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const newLongTermLoan = () => {
        if(number > 0 && number <= canLoan){
            dispatch({type: 'NEWLONGLOAN', payload: {
                    year: term,
                    number: number,
                }});
        }
        else
            alert('无法接受此金额')
    };

    return (
        <div className={classes.longtermloan}>
            <Button variant='outlined' size='small' onClick={handleClickOpen} disabled={quarter === 1 ? false : true}>长期贷款:</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby='form-longtermloan'>
                <DialogTitle id='form-longtermloan'>长期贷款</DialogTitle>
                <DialogContent>
                    <div className={classes.dialog}>
                        <FormControl component='fieldset' >
                            <FormGroup>
                                <FormControlLabel control={
                                    <Tooltip title='输入大于10的整数' arrow>
                                        <TextField id='Longtermloan' type='number'
                                                   label='金额'
                                                   InputLabelProps={{shrink: true}}
                                                   variant='outlined' size='small'
                                                   style={{width: '100px'}}
                                                   value={number}
                                                   onChange={handleInputNumber}
                                                   inputProps={{min: 10, max: canLoan }}/>
                                    </Tooltip>
                                }/>
                            </FormGroup>
                        </FormControl>
                        <FormControl variant='filled'>
                            <InputLabel id='select-label-term'>Year</InputLabel>
                            <Select
                                labelId='select-label-term'
                                id='select-term'
                                value={term}
                                onChange={handleInputTerm}>
                                <MenuItem value={1}>1年</MenuItem>
                                <MenuItem value={2}>2年</MenuItem>
                                <MenuItem value={3}>3年</MenuItem>
                                <MenuItem value={4}>4年</MenuItem>
                                <MenuItem value={5}>5年</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{marginLeft: '20px'}}>
                            <Button variant='contained' onClick={newLongTermLoan}
                                    disabled={ canLoan > 10 ? false : true}>确定</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Typography variant='body2'>{L_term_loan}</Typography>
        </div>
    )
}
