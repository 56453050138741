import React, {useEffect, useState} from "react";
import {Grid, IconButton, Menu} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import useStyles from './styles'
import {useSelector} from "react-redux";
import ProductionLine from "./component/ProductionLine";
import Fbutton from "./component/Fbutton";
import Storebutton from "./component/Storebutton";
import Store from "./component/Store";


export default function Workstation() {
    const classes = useStyles();

    const quarter = useSelector(state => state.game.Quarter);

    const factory1 = useSelector(state => state.game.factory1);
    const factory2 = useSelector(state => state.game.factory2);
    const factory3 = useSelector(state => state.game.factory3);
    const store = useSelector(state => state.game.storehouse);

    return (
        <div>
            <Grid container>
                <Grid item xs={3}>
                    {factory1.expense == 0 ? (
                            <div className={classes.button}>
                                <Fbutton name='factory1'/>
                            </div>
                        ):
                        <div className={classes.factory}>
                            <ProductionLine name='factory1'/>
                        </div>}
                </Grid>
                <Grid item xs={3}>
                    {factory2.expense == 0 ? (
                            <div className={classes.button}>
                                <Fbutton name='factory2'/>
                            </div>
                        ):
                        <div className={classes.factory}>
                            <ProductionLine name='factory2'/>
                        </div>}
                </Grid>
                <Grid item xs={3}>
                    {factory3.expense == 0 ? (
                            <div className={classes.button}>
                                <Fbutton name='factory3'/>
                            </div>
                        ):
                        <div className={classes.factory}>
                            <ProductionLine name='factory3'/>
                        </div>}
                </Grid>
                <Grid item xs={3}>
                    {store.expense == 0 ? (
                            <div className={classes.button}>
                                <Storebutton/>
                            </div>
                        ):
                        <div className={classes.factory}>
                            <Store quarter={quarter}/>
                        </div>}
                </Grid>
            </Grid>
        </div>
    );
}
