import React, {useEffect, useState} from "react";
import {getOrders} from "../../../../Orders/Loadorder";

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    Button,
    FormControl,
    InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@material-ui/core';
import {Paper} from "@material-ui/core";
import {io} from 'socket.io-client';
import {useSelector} from "react-redux";

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com';

let socket;

//components-------------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        flexGrow: 1
    },
}));


//--------------------------------
const Auctions = (props) => {

    //load io connection
    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    const iso9000 = useSelector(state => state.game.ISO9000.develop);
    const iso14000 = useSelector(state => state.game.ISO14000.develop);
    const localMarket = useSelector(state => state.game.localMarket.develop);
    const areaMarket = useSelector(state => state.game.areaMarket.develop);
    const domesticMarket = useSelector(state => state.game.domesticMarket.develop);
    const continentalMarket = useSelector(state => state.game.continentalMarket.develop);
    const internationalMarket = useSelector(state => state.game.internationalMarket.develop);

    const qualification = [''];
    if(iso9000)
        qualification.push('ISO9000');
    if(iso14000)
        qualification.push('ISO14000');
    if(localMarket)
        qualification.push('localMarket');
    if(areaMarket)
        qualification.push('areaMarket');
    if(domesticMarket)
        qualification.push('domesticMarket');
    if(continentalMarket)
        qualification.push('continentalMarket');
    if(internationalMarket)
        qualification.push('internationalMarket');


    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [orders_year1, setOrders_year1] = useState(props.order_year1);
    const [orders_year2, setOrders_year2] = useState(props.order_year2);
    const [orders_year3, setOrders_year3] = useState(props.order_year3);
    const [orders_year4, setOrders_year4] = useState(props.order_year4);
    const [orders_year5, setOrders_year5] = useState(props.order_year5);
    const [orders_year6, setOrders_year6] = useState(props.order_year6);

    const chooseYear_orders = (year) => {
        if(year === 1)
            return orders_year1;
        else if(year === 2)
            return orders_year2;
        else if(year === 3)
            return orders_year3
        else if(year === 4)
            return orders_year4;
        else if(year === 5)
            return orders_year5;
        else
            return orders_year6;
    }

    const [arrivalTime, setArrivalTime] = useState(1);
    const handleInputArrivalTime = (event) => {
        setArrivalTime(event.target.value);
    };

    //set price
    const [price, setPrice] = useState(0);
    const handleInputPrice = (event) => {
        setPrice(event.target.value === '' ? '' : Number(event.target.value).toFixed(0))
    };

    //bid for a order
    const handleBid = (e, name, room, object) => {
        e.preventDefault();
        if(Number.parseInt(price) <= object.cost * 2.5 && Number.parseInt(price) >= 0)
            socket.emit('BIDORDER', name, room, object, arrivalTime, price);
        else
            alert('无效金额')
    }

        return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="P1" {...a11yProps(0)} />
                    <Tab label="P2" {...a11yProps(1)} />
                    <Tab label="P3" {...a11yProps(2)} />
                    <Tab label="P4" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <TableContainer component={Paper}>
                        <Table aria-label='acution' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        产品
                                    </TableCell>
                                    <TableCell align='center'>
                                        数量
                                    </TableCell>
                                    <TableCell align='center'>
                                        市场
                                    </TableCell>
                                    <TableCell align='center'>
                                        ISO
                                    </TableCell>
                                    <TableCell align='center'>
                                        交付时间
                                    </TableCell>
                                    <TableCell align='center'>
                                        成本
                                    </TableCell>
                                    <TableCell align='center' style={{width: '220px'}}>
                                        当前出价
                                    </TableCell>
                                    <TableCell align='center'>
                                        价格
                                    </TableCell>
                                    <TableCell align='center'>
                                        账期
                                    </TableCell>
                                    <TableCell align='center' >
                                        竞拍
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chooseYear_orders(props.year).P1.length === 0
                                    ? <div>loading orders</div>
                                    : chooseYear_orders(props.year).P1.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>{item.product}</TableCell>
                                            <TableCell align='center'>{item.amount}</TableCell>
                                            <TableCell align='center'>{item.market}</TableCell>
                                            <TableCell align='center'>{item.iso}</TableCell>
                                            <TableCell align='center'>第{item.due}季度</TableCell>
                                            <TableCell align='center'>{item.cost}</TableCell>
                                            <Tooltip title={'报价最高为成本的2.5倍'} arrow>
                                                <TableCell align='center'>{
                                                    props.bidorder.filter(bidorder => bidorder._id === item._id).length === 0
                                                        ? <div>no seller</div>
                                                        : (<div>
                                                            售价:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].price + '/'}
                                                            账期:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].arrivalTime}季度
                                                            竞价人:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].owner}
                                                        </div>)
                                                }</TableCell>
                                            </Tooltip>
                                            <TableCell align='center'>
                                                <TextField
                                                    id='price' type='number'
                                                    label='售价'
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined' size='small'
                                                    style={{width: '100px'}}
                                                    onChange={handleInputPrice}
                                                    // onBlur={handleBlurPrice(item.cost)}
                                                    inputProps={{min: 0, max: item.cost * 2.5}}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <FormControl variant='filled'>
                                                    <InputLabel id='select-label-arrivalTime'>账期</InputLabel>
                                                    <Select
                                                        labelId='select-label-arrivalTime'
                                                        id='select-arrivalTime'
                                                        defaultValue={1}
                                                        onChange={handleInputArrivalTime}>
                                                        <MenuItem value={1}>1季度</MenuItem>
                                                        <MenuItem value={2}>2季度</MenuItem>
                                                        <MenuItem value={3}>3季度</MenuItem>
                                                        <MenuItem value={4}>4季度</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button variant='contained'
                                                        disabled={!(qualification.findIndex(q => q === item.iso) !== -1
                                                        && qualification.findIndex(q => q === item.market) !== -1)}
                                                        onClick={e =>handleBid(e, props.name, props.room, item)}>竞拍</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <TableContainer component={Paper}>
                        <Table aria-label='acution' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        产品
                                    </TableCell>
                                    <TableCell align='center'>
                                        数量
                                    </TableCell>
                                    <TableCell align='center'>
                                        市场
                                    </TableCell>
                                    <TableCell align='center'>
                                        ISO
                                    </TableCell>
                                    <TableCell align='center'>
                                        交付时间
                                    </TableCell>
                                    <TableCell align='center'>
                                        成本
                                    </TableCell>
                                    <TableCell align='center' style={{width: '220px'}}>
                                        当前出价
                                    </TableCell>
                                    <TableCell align='center'>
                                        价格
                                    </TableCell>
                                    <TableCell align='center'>
                                        账期
                                    </TableCell>
                                    <TableCell align='center' >
                                        竞拍
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chooseYear_orders(props.year).P2.length === 0
                                    ? <div>loading orders</div>
                                    : chooseYear_orders(props.year).P2.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>{item.product}</TableCell>
                                            <TableCell align='center'>{item.amount}</TableCell>
                                            <TableCell align='center'>{item.market}</TableCell>
                                            <TableCell align='center'>{item.iso}</TableCell>
                                            <TableCell align='center'>第{item.due}季度</TableCell>
                                            <TableCell align='center'>{item.cost}</TableCell>
                                            <Tooltip title={'报价最高为成本的2.5倍'} arrow>
                                                <TableCell align='center'>{
                                                    props.bidorder.filter(bidorder => bidorder._id === item._id).length === 0
                                                        ? <div>no seller</div>
                                                        : (<div>
                                                            售价:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].price + '/'}
                                                            账期:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].arrivalTime}季度
                                                            竞价人:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].owner}
                                                        </div>)
                                                }</TableCell>
                                            </Tooltip>
                                            <TableCell align='center'>
                                                <TextField
                                                    id='price' type='number'
                                                    label='售价'
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined' size='small'
                                                    style={{width: '100px'}}
                                                    onChange={handleInputPrice}
                                                    // onBlur={handleBlurPrice(item.cost)}
                                                    inputProps={{min: 0, max: item.cost * 2.5}}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <FormControl variant='filled'>
                                                    <InputLabel id='select-label-arrivalTime'>账期</InputLabel>
                                                    <Select
                                                        labelId='select-label-arrivalTime'
                                                        id='select-arrivalTime'
                                                        // value={arrivalTime}
                                                        defaultValue={1}
                                                        onChange={handleInputArrivalTime}>
                                                        <MenuItem value={1}>1季度</MenuItem>
                                                        <MenuItem value={2}>2季度</MenuItem>
                                                        <MenuItem value={3}>3季度</MenuItem>
                                                        <MenuItem value={4}>4季度</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button variant='contained'
                                                        disabled={!(qualification.findIndex(q => q === item.iso) !== -1
                                                            && qualification.findIndex(q => q === item.market) !== -1)}
                                                        onClick={e =>handleBid(e, props.name, props.room, item)}>竞拍</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <TableContainer component={Paper}>
                        <Table aria-label='acution' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        产品
                                    </TableCell>
                                    <TableCell align='center'>
                                        数量
                                    </TableCell>
                                    <TableCell align='center'>
                                        市场
                                    </TableCell>
                                    <TableCell align='center'>
                                        ISO
                                    </TableCell>
                                    <TableCell align='center'>
                                        交付时间
                                    </TableCell>
                                    <TableCell align='center'>
                                        成本
                                    </TableCell>
                                    <TableCell align='center' style={{width: '220px'}}>
                                        当前出价
                                    </TableCell>
                                    <TableCell align='center'>
                                        价格
                                    </TableCell>
                                    <TableCell align='center'>
                                        账期
                                    </TableCell>
                                    <TableCell align='center' >
                                        竞拍
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chooseYear_orders(props.year).P3.length === 0
                                    ? <div>loading orders</div>
                                    : chooseYear_orders(props.year).P3.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>{item.product}</TableCell>
                                            <TableCell align='center'>{item.amount}</TableCell>
                                            <TableCell align='center'>{item.market}</TableCell>
                                            <TableCell align='center'>{item.iso}</TableCell>
                                            <TableCell align='center'>第{item.due}季度</TableCell>
                                            <TableCell align='center'>{item.cost}</TableCell>
                                            <Tooltip title={'报价最高为成本的2.5倍'} arrow>
                                                <TableCell align='center'>{
                                                    props.bidorder.filter(bidorder => bidorder._id === item._id).length === 0
                                                        ? <div>no seller</div>
                                                        : (<div>
                                                            售价:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].price + '/'}
                                                            账期:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].arrivalTime}季度
                                                            竞价人:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].owner}
                                                        </div>)
                                                }</TableCell>
                                            </Tooltip>
                                            <TableCell align='center'>
                                                <TextField
                                                    id='price' type='number'
                                                    label='售价'
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined' size='small'
                                                    style={{width: '100px'}}
                                                    onChange={handleInputPrice}
                                                    // onBlur={handleBlurPrice(item.cost)}
                                                    inputProps={{min: 0, max: item.cost * 2.5}}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <FormControl variant='filled'>
                                                    <InputLabel id='select-label-arrivalTime'>账期</InputLabel>
                                                    <Select
                                                        labelId='select-label-arrivalTime'
                                                        id='select-arrivalTime'
                                                        // value={arrivalTime}
                                                        defaultValue={1}
                                                        onChange={handleInputArrivalTime}>
                                                        <MenuItem value={1}>1季度</MenuItem>
                                                        <MenuItem value={2}>2季度</MenuItem>
                                                        <MenuItem value={3}>3季度</MenuItem>
                                                        <MenuItem value={4}>4季度</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button variant='contained'
                                                        disabled={!(qualification.findIndex(q => q === item.iso) !== -1
                                                            && qualification.findIndex(q => q === item.market) !== -1)}
                                                        onClick={e =>handleBid(e, props.name, props.room, item)}>竞拍</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <TableContainer component={Paper}>
                        <Table aria-label='acution' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        产品
                                    </TableCell>
                                    <TableCell align='center'>
                                        数量
                                    </TableCell>
                                    <TableCell align='center'>
                                        市场
                                    </TableCell>
                                    <TableCell align='center'>
                                        ISO
                                    </TableCell>
                                    <TableCell align='center'>
                                        交付时间
                                    </TableCell>
                                    <TableCell align='center'>
                                        成本
                                    </TableCell>
                                    <TableCell align='center' style={{width: '220px'}}>
                                        当前出价
                                    </TableCell>
                                    <TableCell align='center'>
                                        价格
                                    </TableCell>
                                    <TableCell align='center'>
                                        账期
                                    </TableCell>
                                    <TableCell align='center' >
                                        竞拍
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chooseYear_orders(props.year).P4.length === 0
                                    ? <div>loading orders</div>
                                    : chooseYear_orders(props.year).P4.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>{item.product}</TableCell>
                                            <TableCell align='center'>{item.amount}</TableCell>
                                            <TableCell align='center'>{item.market}</TableCell>
                                            <TableCell align='center'>{item.iso}</TableCell>
                                            <TableCell align='center'>第{item.due}季度</TableCell>
                                            <TableCell align='center'>{item.cost}</TableCell>
                                            <Tooltip title={'报价最高为成本的2.5倍'} arrow>
                                                <TableCell align='center'>{
                                                    props.bidorder.filter(bidorder => bidorder._id === item._id).length === 0
                                                        ? <div>no seller</div>
                                                        : (<div>
                                                            售价:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].price + '/'}
                                                            账期:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].arrivalTime}季度
                                                            竞价人:{props.bidorder.filter(bidorder => bidorder._id === item._id)[0].owner}
                                                        </div>)
                                                }</TableCell>
                                            </Tooltip>
                                            <TableCell align='center'>
                                                <TextField
                                                    id='price' type='number'
                                                    label='售价'
                                                    InputLabelProps={{shrink: true}}
                                                    variant='outlined' size='small'
                                                    style={{width: '100px'}}
                                                    onChange={handleInputPrice}
                                                    // onBlur={handleBlurPrice(item.cost)}
                                                    inputProps={{min: 0, max: item.cost * 2.5}}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <FormControl variant='filled'>
                                                    <InputLabel id='select-label-arrivalTime'>账期</InputLabel>
                                                    <Select
                                                        labelId='select-label-arrivalTime'
                                                        id='select-arrivalTime'
                                                        // value={arrivalTime}
                                                        defaultValue={1}
                                                        onChange={handleInputArrivalTime}>
                                                        <MenuItem value={1}>1季度</MenuItem>
                                                        <MenuItem value={2}>2季度</MenuItem>
                                                        <MenuItem value={3}>3季度</MenuItem>
                                                        <MenuItem value={4}>4季度</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button variant='contained'
                                                        disabled={!(qualification.findIndex(q => q === item.iso) !== -1
                                                            && qualification.findIndex(q => q === item.market) !== -1)}
                                                        onClick={e =>handleBid(e, props.name, props.room, item)}>竞拍</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}

export default Auctions;
