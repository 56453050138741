const initalstate = {
    Finance: {
        processingExpense: 10,

        R1: {
            normalPrice: 10,
            urgentPrice: 20
        },
        R2: {
            normalPrice: 10,
            urgentPrice: 20
        },
        R3: {
            normalPrice: 10,
            urgentPrice: 20
        },
        R4: {
            normalPrice: 10,
            urgentPrice: 20
        },
        P1: {
            normalPrice: 60,
            urgentPrice: 90
        },
        P2: {
            normalPrice: 90,
            urgentPrice: 135
        },
        P3: {
            normalPrice: 90,
            urgentPrice: 135
        },
        P4: {
            normalPrice: 150,
            urgentPrice: 225
        },
        LongLendingrate: 0.1,
        ShortLendingrate: 0.05,

        handmadeline: {
            expense: 35,
            installTerm: 0,
            produceTerm: [false, false],
            maintenance: 10,
            conversion: {
                Term: 0,
                expense: 0
            },
            depreciation: {
                year: 3,
                expense: 10,
                salvageValue: 5
            },
        },
        automaticline: {
            expense: 150,
            installTerm: [true, false, false],
            produceTerm: [false],
            maintenance: 20,
            conversion: {
                Term: 1,
                expense: 20
            },
            depreciation: {
                year: 4,
                expense: 30,
                salvageValue: 30
            },
        },
        flexibleline: {
            expense: 200,
            installTerm: [true, false, false, false],
            produceTerm: [false],
            maintenance: 30,
            conversion: {
                Term: 0,
                expense: 0
            },
            depreciation: {
                year: 4,
                expense: 40,
                salvageValue: 40
            },
        },

        Factory: {
            small: {
                rent: 18,
                buy: 180,
                space: [{},{}]
            },
            middle: {
                rent: 30,
                buy: 300,
                space: [{},{},{}]
            },
            large: {
                rent: 44,
                buy: 440,
                space: [{},{},{},{}]
            },
        },

        Store: {
            small: {
                rent: 10,
                buy: 100,
                space: 10
            },
            middle: {
                rent: 20,
                buy: 200,
                space: 20
            },
            large: {
                rent: 30,
                buy: 300,
                space: 40
            },
        },

    },

    ClickonceEachTerm: {
        Building_Produce: [],
        Develop: []
    },

    Quarter: 1,

    Cash: 800,

    Income: 0,

    Cost: 0,

    In_processing: 0,

    ManagementFee: 0,

    MaintainFee: 0,

    Lines_depreciation: 0,

    Rent: {
        factory: 0,
        store: 0,
    },

    //贴息 利息
    FinancialCost: 0,

    DevelopFee: 0,

    ChangeCost: 0,


// {amount: 100, quarter: 2}, {amount: 50, quarter: 3}
    //longtermloan {amount: 123, year: 123, repay: [1,1,1, 110]}
    //shorttermloan {amount: 123, repay: [0,0,0,129]}
    ShortTermLoan: [],
    LongTermLoan: [],
    //{product: 'P1', amount: 4, price: 100, due: 2, revenuetime: 1, cost: 80}
    receivableAccounts: [],
    Order: [],
    R1: {
        currentStock: 0,
        transit: 0
    },
    R2: {
        currentStock: 0,
        transit: 0
    },
    R3: {
        currentStock: 0,
        transit: 0
    },
    R4: {
        currentStock: 0,
        transit: 0
    },
    P1: {
        develop: false,
        progress: [0,0],
        currentStock: 0,
        transit: 0
    },
    P2: {
        develop: false,
        progress: [0,0,0],
        currentStock: 0,
        transit: 0
    },
    P3: {
        develop: false,
        progress: [0,0,0,0],
        currentStock: 0,
        transit: 0
    },
    P4: {
        develop: false,
        progress: [0,0,0,0,0],
        currentStock: 0,
        transit: 0
    },

    ISO9000: {
        develop: false,
        progress: [0,0,0,0]
    },
    ISO14000: {
        develop: false,
        progress: [0,0,0,0]
    },
    localMarket: {
        develop: false,
        progress: [0,0,0,0]
    },
    areaMarket: {
        develop: false,
        progress: [0,0,0,0]
    },
    domesticMarket: {
        develop: false,
        progress: [0,0,0,0]
    },
    continentalMarket: {
        develop: false,
        progress: [0,0,0,0]
    },
    internationalMarket: {
        develop: false,
        progress: [0,0,0,0]
    },

    // handmadeLine: [],
    // automaticLine: [],
    // flexibleLine: [],

    factory1: {
        expense: 0,
        property: '',
        size: '',
        line: []
    },
    factory2: {
        expense: 0,
        property: '',
        size: '',
        line: []
    },
    factory3: {
        expense: 0,
        property: '',
        size: '',
        line: []
    },
    storehouse: {
        expense: 0,
        property: '',
        size: '',
        storage: 0
    }
}

export default initalstate;
