import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    developstate: {
        display: 'flex',
        flexDirection: 'row',
        width: '420px',
        marginTop: '10px'
    }
}));
