import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import React, {useState} from "react";

import useStyles from './styles';

export default function MarketandDevelop() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const clickonce = useSelector(state => state.game.ClickonceEachTerm.Develop);

    const P1 = useSelector(state => state.game.P1);
    const P2 = useSelector(state => state.game.P2);
    const P3 = useSelector(state => state.game.P3);
    const P4 = useSelector(state => state.game.P4);
    const ISO9000 = useSelector(state => state.game.ISO9000);
    const ISO14000 = useSelector(state => state.game.ISO14000);
    const localMarket = useSelector(state => state.game.localMarket);
    const areaMarket = useSelector(state => state.game.areaMarket);
    const domesticMarket = useSelector(state => state.game.domesticMarket);
    const continentalMarket = useSelector(state => state.game.continentalMarket);
    const internationalMarket = useSelector(state => state.game.internationalMarket);

    const P1Button = () => {
        dispatch({type: 'DEVELOP', payload: 'P1'})
    };
    const P2Button = () => {
        dispatch({type: 'DEVELOP', payload: 'P2'})
    };
    const P3Button = () => {
        dispatch({type: 'DEVELOP', payload: 'P3'})
    };
    const P4Button = () => {
        dispatch({type: 'DEVELOP', payload: 'P4'})
    };
    const ISO9000_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'ISO9000'})
    };
    const ISO14000_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'ISO14000'})
    };
    const localMarket_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'localMarket'})
    };
    const areaMarket_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'areaMarket'})
    };
    const domesticMarket_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'domesticMarket'})
    };
    const continentalMarket_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'continentalMarket'})
    };
    const internationalMarket_Button = () => {
        dispatch({type: 'DEVELOP', payload: 'internationalMarket'})
    };

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant='contained' color='primary' onClick={handleClickOpen}>市场开拓/研发</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">研发与市场开拓</DialogTitle>
                <DialogContent>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>P1</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {P1.develop ? <Typography>研发完成</Typography> : <Typography>未研发完成</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {P1.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={P1Button}
                                disabled={Boolean(clickonce.find(item => item === 'P1')) || P1.develop}>研发</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>P2</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {P2.develop ? <Typography>研发完成</Typography> : <Typography>未研发完成</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {P2.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={P2Button}
                                disabled={Boolean(clickonce.find(item => item === 'P2')) || P2.develop}>研发</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>P3</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {P3.develop ? <Typography>研发完成</Typography> : <Typography>未研发完成</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {P3.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={P3Button}
                                disabled={Boolean(clickonce.find(item => item === 'P3')) || P3.develop}>研发</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>P4</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {P4.develop ? <Typography>研发完成</Typography> : <Typography>未研发完成</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {P4.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={P4Button}
                                disabled={Boolean(clickonce.find(item => item === 'P4')) || P4.develop}>研发</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>ISO9000</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {ISO9000.develop ? <Typography>已取得资格</Typography> : <Typography>未取得资格</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {ISO9000.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={ISO9000_Button}
                                disabled={Boolean(clickonce.find(item => item === 'ISO9000')) || ISO9000.develop}>认证</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '10px'}}>ISO14000</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {ISO14000.develop ? <Typography>已取得资格</Typography> : <Typography>未取得资格</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {ISO14000.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={ISO14000_Button}
                                disabled={Boolean(clickonce.find(item => item === 'ISO14000')) || ISO14000.develop}>认证</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>localMarket</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {localMarket.develop ? <Typography>已开拓</Typography> : <Typography>未开拓</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {localMarket.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={localMarket_Button}
                                disabled={Boolean(clickonce.find(item => item === 'localMarket')) || localMarket.develop}>开拓</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>areaMarket</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {areaMarket.develop ? <Typography>已开拓</Typography> : <Typography>未开拓</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {areaMarket.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={areaMarket_Button}
                                disabled={Boolean(clickonce.find(item => item === 'areaMarket')) || areaMarket.develop}>开拓</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>domesticMarket</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {domesticMarket.develop ? <Typography>已开拓</Typography> : <Typography>未开拓</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {domesticMarket.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={domesticMarket_Button}
                                disabled={Boolean(clickonce.find(item => item === 'domesticMarket')) || domesticMarket.develop}>开拓</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>continentalMarket</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {continentalMarket.develop? <Typography>已开拓</Typography> : <Typography>未开拓</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {continentalMarket.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={continentalMarket_Button}
                                disabled={Boolean(clickonce.find(item => item === 'continentalMarket')) || continentalMarket.develop}>开拓</Button>
                    </div>
                    <div className={classes.developstate}>
                        <Typography style={{'marginRight': '20px'}}>internationalMarket</Typography>
                        <Tooltip title='state' aria-label='state' style={{'marginRight': '20px'}} arrow>
                            {internationalMarket.develop ? <Typography>已开拓</Typography> : <Typography>未开拓</Typography>}
                        </Tooltip>
                        <Tooltip title='progress' aria-label='progress' arrow>
                            <div>
                                {internationalMarket.progress.map((item, index) => {
                                    if(item === 0)
                                        return <EmojiObjectsIcon key={index}/>
                                    else
                                        return <EmojiObjectsIcon key={index} color='primary'/>
                                })}
                            </div>
                        </Tooltip>
                        <Button variant='contained' size='small'
                                style={{'marginLeft': 'auto'}}
                                onClick={internationalMarket_Button}
                                disabled={Boolean(clickonce.find(item => item === 'internationalMarket')) || internationalMarket.develop}>开拓</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
