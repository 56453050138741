import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';
import {useDispatch, useSelector} from "react-redux";
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@material-ui/core";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export default function Buildline() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const disableFactory1 = useSelector(state => state.game.factory1.expense) > 0 ? false : true;
    const disableFactory2 = useSelector(state => state.game.factory2.expense) > 0 ? false : true;
    const disableFactory3 = useSelector(state => state.game.factory3.expense) > 0 ? false : true;
    const disable_button = disableFactory1 && disableFactory2 && disableFactory3;

    const [factory, setFactory] = useState(null);
    const [line, setLine] = useState(null);
    const [product, setProduct] = useState(null);
    const [check, setCheck] = useState(true);

    const handleChange_factory = (event) => {
        setFactory(event.target.value);
    };
    const handleChange_line = (event) => {
        setLine(event.target.value);
    };
    const handleChange_product = (event) => {
        setProduct(event.target.value);
    };

    useEffect(() => {
        if(factory !== null && line !== null && product !== null)
            setCheck(false);
    },[factory,line,product]);

    const [Snackbar_line, setSnackbar_line] = useState(false);
    const handleClose_Snackbar = () => {
        setSnackbar_line(false);
    }

    const handlecreate = () => {
        setSnackbar_line(true);
        dispatch({type: 'NEWLINE', payload: {
                factory: factory,
                lineType: line,
                product: product}});
    };

    return (
        <div>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={Snackbar_line} autoHideDuration={1000} onClose={handleClose_Snackbar}>
                <Alert variant='filled' severity='success'>
                    新建{line}
                </Alert>
            </Snackbar>
            <Button variant='contained' color="primary" onClick={handleClickOpen} disabled={disable_button}>
                新建生产线
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">新建生产线</DialogTitle>
                <DialogContent className={classes.line}>
                    <div className={classes.factory}>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>factory</FormLabel>
                            <RadioGroup aria-label='factory' name='factory' value={factory} onChange={handleChange_factory} row>
                                <FormControlLabel control={<Radio />} label='Factory#1' value='factory1' disabled={disableFactory1}/>
                                <FormControlLabel control={<Radio />} label='Factory#2' value='factory2' disabled={disableFactory2}/>
                                <FormControlLabel control={<Radio />} label='Factory#3' value='factory3' disabled={disableFactory3}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>line type</FormLabel>
                            <RadioGroup aria-label='line' name='line' value={line} onChange={handleChange_line} row>
                                <FormControlLabel control={<Radio />} label='handmadeline' value='handmade'/>
                                <FormControlLabel control={<Radio />} label='automaticline' value='automatic'/>
                                <FormControlLabel control={<Radio />} label='flexibleline' value='flexible'/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>Product</FormLabel>
                            <RadioGroup aria-label='product' name='product' value={product} onChange={handleChange_product} row>
                                <FormControlLabel control={<Radio />} label='P1' value='P1' />
                                <FormControlLabel control={<Radio />} label='P2' value='P2' />
                                <FormControlLabel control={<Radio />} label='P3' value='P3' />
                                <FormControlLabel control={<Radio />} label='P4' value='P4' />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handlecreate} disabled={check}>
                        Submit
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
