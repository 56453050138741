import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from './styles';
import {useDispatch, useSelector} from "react-redux";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from '@material-ui/core';
import {Paper} from "@material-ui/core";
import {Menu, MenuItem} from "@material-ui/core";
import LongtermLoan from "./component/LongtermLoan/LongtermLoan";
import ShorttermLoan from "./component/ShorttermLoan/ShorttermLoan";
import ReceivablesandLoan from "./component/ReceivableandLoan/ReceivablesandLoan";
import {io} from 'socket.io-client';
import {useHistory} from "react-router-dom";

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com';
let socket;

export default function FinancialStatement(props) {
    const classes = useStyles();

    const quarter = useSelector(state => state.game.Quarter);

    const cash = useSelector(state => state.game.Cash);
    const receivableAccounts = useSelector(state => state.game.receivableAccounts);
    const shortLoan = useSelector(state => state.game.ShortTermLoan);
    const longLoan = useSelector(state => state.game.LongTermLoan);
    const in_processing = useSelector(state => state.game.In_processing);
    const FinishedGoods = useSelector(state => state.game.P1.currentStock) * 20
        + useSelector(state => state.game.P2.currentStock) * 30
        + useSelector(state => state.game.P3.currentStock) * 30
        + useSelector(state => state.game.P4.currentStock) * 40;

    const materials = useSelector(state => state.game.R1.currentStock) * 10
        + useSelector(state => state.game.R2.currentStock) * 10
        + useSelector(state => state.game.R3.currentStock) * 10
        + useSelector(state => state.game.R4.currentStock) * 10;

    const income = useSelector(state => state.game.Income);

    const cost = useSelector(state => state.game.Cost);

    const factory1 = useSelector(state => state.game.factory1);
    const factory2 = useSelector(state => state.game.factory2);
    const factory3 = useSelector(state => state.game.factory3);
    const factorys = (factory1.property === 'buy' ? factory1.expense : 0)
        + (factory2.property === 'buy' ? factory2.expense : 0)
        + (factory3.property === 'buy' ? factory3.expense : 0);

    const storehouse = useSelector(state => state.game.storehouse);
    const store = (storehouse.property === 'buy' ? storehouse.expense : 0);

    let lines_factory1 = 0;
    factory1.line.map(item => {
        if(Object.keys(item).length !== 0){
            if(item === 'complete')
                lines_factory1 = lines_factory1 + item.depreciation.reduce((a,b)=> a+b, 0);
            else
                lines_factory1 = lines_factory1 + item.expense;
        }
    });
    let lines_factory2 = 0;
    factory2.line.map(item => {
        if(Object.keys(item).length !== 0){
            if(item === 'complete')
                lines_factory2 = lines_factory2 + item.depreciation.reduce((a,b)=> a+b, 0);
            else
                lines_factory2 = lines_factory2 + item.expense;
        }
    });
    let lines_factory3 = 0;
    factory3.line.map(item => {
        if(Object.keys(item).length !== 0){
            if(item === 'complete')
                lines_factory3 = lines_factory3 + item.depreciation.reduce((a,b)=> a+b, 0);
            else
                lines_factory3 = lines_factory3 + item.expense;
        }
    });

    const loan = shortLoan.reduce(function (accumulator, item){return accumulator + item.amount}, 0)
        + longLoan.reduce(function (accumulator, item){return accumulator + item.amount}, 0)

    const owner_equity = cash
        + receivableAccounts.reduce(function (accumulator, item){return accumulator + item.price}, 0)
        + in_processing + FinishedGoods + materials
        + factorys + store + lines_factory1 + lines_factory2 + lines_factory3
        - loan;

    //load io connection
    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    useEffect(() => {
        socket.emit('update_owner_equity', props.name, props.room, owner_equity);
    },[props.year]);

    //update owner equity finally
    if(props.year === 7 && props.time.minute === 0 && props.time.second === 3)
        socket.emit('update_owner_equity', props.name, props.room, owner_equity);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleLeave = () => {
        socket.emit('LEAVE', props.name, props.room);
        dispatch({type: 'RESET'});
        history.push(`/home`);
    }

    //automatically leave after bankruptcy
    if(cash < 0){
        setTimeout(()=> {
            dispatch({type: 'RESET'});
            socket.emit('LEAVE', props.name, props.room)
            history.push(`/home`);
        }, 5000)
    }

    // comprehensive cost
    const mangementcost = useSelector(state => state.game.ManagementFee);
    const maintainFee_lines = useSelector(state => state.game.MaintainFee);
    const rent_factory = useSelector(state => state.game.Rent.factory);
    const rent_store = useSelector(state => state.game.Rent.store);
    const developcost = useSelector(state => state.game.DevelopFee);
    const changeproduce = useSelector(state => state.game.ChangeCost);


    //earnings before tax
    const financialCost = useSelector(state => state.game.FinancialCost);
    let comprehensiveCost = mangementcost + maintainFee_lines
        + rent_factory + rent_store
        + developcost + changeproduce;

    const Lines_depreciation = useSelector(state => state.game.Lines_depreciation);

    let EarningsbeforeTax = income - cost - comprehensiveCost - Lines_depreciation - financialCost;

    //income tax
    let incomeTax = (EarningsbeforeTax * 0.25).toFixed(0);
    let netProfit = (EarningsbeforeTax * 0.75).toFixed(0);

    const [anchorEl_comprehensiveCost, setAnchorEl_comprehensiveCost] = useState(null);
    const handleClick_comprehensiveCost = (event) => {
        setAnchorEl_comprehensiveCost(event.currentTarget);
    };
    const handleClose_comprehensiveCost = () => {
        setAnchorEl_comprehensiveCost(null);
    }

    const [anchorEl_FinancialCost, setAnchorEl_FinancialCost] = useState(null);
    const handleClick_FinancialCost = (event) => {
        setAnchorEl_FinancialCost(event.currentTarget);
    };
    const handleClose_FinancialCost = () => {
        setAnchorEl_FinancialCost(null);
    }

    const [anchorEl_IncomeTax, setAnchorEl_IncomeTax] = useState(null);
    const handleClick_IncomeTax = (event) => {
        setAnchorEl_IncomeTax(event.currentTarget);
    };
    const handleClose_IncomeTax = () => {
        setAnchorEl_IncomeTax(null);
    }



    return (
        <div>
            <div className={classes.time_button}>
                <Typography variant='h6' color='secondary'>
                    时间 {props.time.minute}:{props.time.second}
                </Typography>
                <Button variant='contained' size='small' onClick={handleLeave}>离开房间</Button>
            </div>
            <div className={classes.finance}>
                <div className={classes.header}>
                    {quarter === 4
                        ? <Typography variant='subtitle2'>第{quarter}季度/等待订货会</Typography>
                        : <Typography variant='subtitle2'>第{props.year}年第{quarter}季度</Typography>}
                    <div style={{display: 'flex'}}>
                        <Typography variant='subtitle2'>公司状况：</Typography>
                        {cash >= 0
                            ? <Typography variant='subtitle2'>正在运营</Typography>
                            : <Typography variant='subtitle2'>破产</Typography>}
                    </div>
                </div>
                <div className={classes.information}>
                    <TableContainer component={Paper}>
                        <Table aria-label='finance' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' colSpan={3}>
                                        <Typography variant='subtitle2'>
                                            财务
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        现金:{cash}
                                    </TableCell>
                                    <TableCell>销售收入:{income}</TableCell>
                                    <TableCell>总成本:{cost}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>权益:{owner_equity}</TableCell>
                                    <TableCell>
                                        <LongtermLoan owner_equity={owner_equity}/>
                                    </TableCell>
                                    <TableCell>
                                        <ShorttermLoan owner_equity={owner_equity}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Button size='small' onClick={handleClick_comprehensiveCost}>
                                            综合费用:{mangementcost
                                        + maintainFee_lines
                                        + rent_factory
                                        + rent_store
                                        + developcost
                                        + changeproduce}
                                        </Button>
                                        <Menu
                                            id='comprehensiveCost-menu'
                                            anchorEl={anchorEl_comprehensiveCost}
                                            keepMounted
                                            open={Boolean(anchorEl_comprehensiveCost)}
                                            onClose={handleClose_comprehensiveCost}>
                                            <MenuItem>管理费用:{mangementcost}</MenuItem>
                                            <MenuItem>生产线维护费:{maintainFee_lines}</MenuItem>
                                            <MenuItem>厂房租金:{rent_factory}</MenuItem>
                                            <MenuItem>仓库租金:{rent_store}</MenuItem>
                                            <MenuItem>研发/市场开拓/ISO认证:{developcost}</MenuItem>
                                            <MenuItem>转产费:{changeproduce}</MenuItem>
                                        </Menu>
                                    </TableCell>
                                    <TableCell>
                                        <Button size='small' onClick={handleClick_FinancialCost}>
                                            税前利润:{EarningsbeforeTax}
                                        </Button>
                                        <Menu
                                            id='FinancialCost-menu'
                                            anchorEl={anchorEl_FinancialCost}
                                            keepMounted
                                            open={Boolean(anchorEl_FinancialCost)}
                                            onClose={handleClose_FinancialCost}>
                                            <MenuItem>销售收入:{income}</MenuItem>
                                            <MenuItem>直接成本:{cost}</MenuItem>
                                            <MenuItem>毛利:{income - cost}</MenuItem>
                                            <MenuItem>综合费用:{comprehensiveCost}</MenuItem>
                                            <MenuItem>折旧:{Lines_depreciation}</MenuItem>
                                            <MenuItem>财务费用:{financialCost}</MenuItem>
                                            <MenuItem>税前利润:{EarningsbeforeTax}</MenuItem>
                                        </Menu>
                                    </TableCell>
                                    <TableCell>
                                        <Button size='small' onClick={handleClick_IncomeTax}>
                                            净利润:{netProfit > 0 ? netProfit : 0}
                                        </Button>
                                        <Menu
                                            id='IncomeTax-menu'
                                            anchorEl={anchorEl_IncomeTax}
                                            keepMounted
                                            open={Boolean(anchorEl_IncomeTax)}
                                            onClose={handleClose_IncomeTax}>
                                            <MenuItem>所得税:{EarningsbeforeTax > 0 ? incomeTax : 0}</MenuItem>
                                            <MenuItem>净利润:{netProfit}</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.balance}>
                    <ReceivablesandLoan/>
                </div>
            </div>
        </div>
    )
}
