
import * as api from '../api/index';

export const getOrders = async () => {
    try {
        const {data} = await api.fetchOrders();
        return data || [];
    } catch (error){
        console.log(error);
    }
}
