import React from 'react';
import {Container, CssBaseline, Grow} from '@material-ui/core';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Auth from "./components/Auth/Auth";
import Home from './components/Home/Home';
import Game from "./components/Game/Game";
import Navbar from "./components/Navbar/Navbar";

const App = () => (
    <BrowserRouter>
        <Container maxWidth='lg'>
            <CssBaseline />
            <Navbar/>
            <Grow in>
                <Switch>
                    <Route path="/" exact component={Auth} />
                    <Route path="/home" component={Home} />
                    <Route path="/game" component={Game} />
                    <Route path="*"  component={Home} />
                </Switch>
            </Grow>
        </Container>
    </BrowserRouter>
)

export default App;
