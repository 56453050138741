import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import {Paper} from "@material-ui/core";
import {useState} from "react";

export default function OrdersMenu() {
    const dispatch = useDispatch();

    const receivableAccounts = useSelector(state => state.game.receivableAccounts);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [number, setNumber] = useState(0);
    const handleInputNumber = (event) => {
        setNumber(event.target.value === '' ? '' : Number(event.target.value).toFixed(0))
    };

    const handleDiscount = (e, index, price) => {
        e.preventDefault();
        console.log(index, number);
        if(number === 0)
            alert('请输入大于0的整数');
        else if(number > price)
            alert('贴现额大于面值')
        else if(price >= number){
            dispatch({type: 'DISCOUNT', payload: {
                    index: index,
                    money: number
                }})
        }
    }

    return (
        <div>
            <Button variant='contained' color='primary' onClick={handleClickOpen}>应收账款</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby='form-receivables'>
                <DialogTitle id='form-receivables'>应收帐款</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label='receivables' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        金额
                                    </TableCell>
                                    <TableCell align='center'>
                                        账期
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {receivableAccounts.length === 0
                                    ? <div>没有应收账款</div>
                                    : receivableAccounts.map((item, index) =>(
                                        <TableRow key={index}>
                                            <TableCell align='center'>{item.price}</TableCell>
                                            <TableCell align='center'>{item.revenuetime}</TableCell>
                                            <TableCell align='center'>
                                                <TextField id='discount' type='number'
                                                           label='金额'
                                                           InputLabelProps={{shrink: true}}
                                                           variant='outlined' size='small'
                                                           style={{width: '100px'}}
                                                           onChange={handleInputNumber}
                                                           inputProps={{min: 0, max: item.price }}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button variant='contained' color='primary'
                                                        onClick={e =>handleDiscount(e,index, item.price)}>贴现</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    );
}
