import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {allReducers} from "./index";

function saveToLocalStorage(state) {
    try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem('gameState', serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem('gameState');
        if(serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const store = createStore(allReducers, { game: loadFromLocalStorage() }, compose(applyMiddleware(thunk)));
// const store = createStore(allReducers, { game: loadFromLocalStorage() }, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));
//load only gamestate
store.subscribe(() => saveToLocalStorage(store.getState().game));

export default store;
