import useStyles from './styles';
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import {Paper} from "@material-ui/core";
import {Tooltip} from "@material-ui/core";

export default function ReceivablesandLoan() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const receivables = useSelector(state => state.game.receivableAccounts);
    const orders = useSelector(state => state.game.Order);
    const shortLoan = useSelector(state => state.game.ShortTermLoan);
    const longLoan = useSelector(state => state.game.LongTermLoan);

    const handlePayorder = (e, i) => {
        e.preventDefault();
        dispatch({type: 'PAYORDER', payload: {
            order_index: i
            }});
    };

    return (
        <div className={classes.loanandreceivable}>
            <TableContainer component={Paper}>
                <Table aria-lable='table' size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>贷款期限</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shortLoan.map((item, index) =>(
                            <TableRow key={index}>
                                <Tooltip title={
                                    '还款周期:' + item.repay.join()
                                } arrow>
                                    <TableCell component='th' scope='row'>
                                        短期贷款:{item.amount}
                                    </TableCell>
                                </Tooltip>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableBody>
                        {longLoan.map((item, index) =>(
                            <TableRow key={index}>
                                <Tooltip title={
                                    '还款周期:' + item.repay.join()
                                } arrow>
                                    <TableCell component='th' scope='row'>
                                        长期贷款:{item.amount}
                                    </TableCell>
                                </Tooltip>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table aria-lable='table' size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' colSpan={2}>应收帐款</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {receivables.map((row, index) => (
                            <TableRow key={index}>
                                <Tooltip title={'账期:' + row.revenuetime + '季度'} arrow>
                                    <TableCell align='center'>
                                        金额:{row.price}
                                    </TableCell>
                                </Tooltip>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table aria-lable='table' size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' colSpan={2}>订单</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((row, index) => (
                            <TableRow key={index}>
                                <Tooltip title={'第'+ row.due + '季度'} arrow>
                                    <TableCell component='th' scope='row'>
                                        {row.product}:{row.amount}
                                    </TableCell>
                                </Tooltip>
                                <Tooltip title={'金额:' + row.price + 'W'} arrow>
                                    <Button variant='outlined' onClick={e =>handlePayorder(e, index)}>交付</Button>
                                </Tooltip>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
