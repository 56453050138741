import {Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link, Route, Switch} from "react-router-dom";
import {Button} from "@material-ui/core";
import {FormControl, InputLabel, FilledInput} from "@material-ui/core";
import useStyles from "./styles";
import Rule from "./components/Rule";
import Market from "./components/Market";
import {io}  from 'socket.io-client';
import {useHistory} from "react-router-dom";
import Rooms from "./components/Rooms";
import {Paper} from "@material-ui/core";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Avatar} from "@material-ui/core";
import discord from '../../Images/discord_icon.jpeg';
import kaihei from '../../Images/kaihei_icon.png';

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com/';
let socket;

const Home = () => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const [name, setName] = useState('');
    const [room, setRoom] = useState('');
    const [password, setPassword] = useState('');
    const [number, setNumber] = useState(4);

    const [description, setDescription] = useState('');

    const [time_round, setTime_round] = useState(10);
    const [time_auction, setTime_auction] = useState(5);

    const history = useHistory();

    const classes = useStyles();

    useEffect(() => {
        socket = io(ENDPOINT);
    }, [ENDPOINT]);

    useEffect(() => {
        socket.on('CanCreateGame', (n, r, arg) => {
            if(arg){
                socket.off();
                socket.disconnect();
                history.push(`/game?name=${n}&room=${r}`);
            }
        });
    },[]);

    const [Snackbar_create, setSnackbar_create] = useState(false);
    const [alert_create, setAlert_create] = useState('');

    const handleClose_create = () => {
        setSnackbar_create(false);
    };

    const handleCreateGame = () => {
        if(!name || !room){
            setSnackbar_create(true);
            setAlert_create('Name and room are required');
        }
        else if(number < 1){
            setSnackbar_create(true);
            setAlert_create(' at least 1 player')
        }
        else{
            console.log(name, room, password, description, number);
            socket.emit('CreateRoom', name, room, password, description, number, time_round, time_auction, (response) => {
                alert(response);
            });
        }
    };

    //check islogin
    if(!user)
        history.push(`/`);

    return (
        <div>
            {/*Snackbar*/}
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={Snackbar_create} autoHideDuration={2000} onClose={handleClose_create}>
                <Alert variant='filled' severity='warning'>
                    {alert_create}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid item xs={2}>
                    <Paper elevation={3}>
                        <div className={classes.menu}>
                            <div className={classes.input}>
                                <Typography>创建房间</Typography>
                                <FormControl variant='filled' size='small'>
                                    <InputLabel htmlFor='filled-create-name'>昵称</InputLabel>
                                    <FilledInput id='filled-create-name'
                                                 type='text'
                                                 onChange={(event) => setName(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant='filled' size='small'>
                                    <InputLabel htmlFor='filled-create-room'>房间名</InputLabel>
                                    <FilledInput id='filled-create-room'
                                                 type='text'
                                                 onChange={(event) => setRoom(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant='filled' size='small'>
                                    <InputLabel htmlFor='filled-create-password'>密码(可选)</InputLabel>
                                    <FilledInput id='filled-create-password'
                                                 type='text'
                                                 onChange={(event) => setPassword(event.target.value)}
                                    />
                                </FormControl>
                                <TextField
                                    style={{marginTop: '10px'}}
                                    id='description'
                                    label='备注(可选)'
                                    multiline
                                    maxRows={8}
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                    variant='outlined'
                                />
                                <div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
                                    <Typography variant='subtitle1' style={{marginLeft: '40px'}}>Year:</Typography>
                                    <TextField style={{width: '85px', marginLeft: '7px'}} type='number' size='small' variant='outlined'
                                               id='number'
                                               label='minutes'
                                               defaultValue={10}
                                               inputProps={{min: 1}}
                                               onChange={(event) => setTime_round(event.target.value)}
                                    />
                                </div>
                                <div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
                                    <Typography variant='subtitle1' style={{marginLeft: '20px'}}>Auction:</Typography>
                                    <TextField style={{width: '85px', marginLeft: '5px'}} type='number' size='small' variant='outlined'
                                               id='number'
                                               label='minutes'
                                               defaultValue={5}
                                               inputProps={{min: 1}}
                                               onChange={(event) => setTime_auction(event.target.value)}
                                    />
                                </div>
                                <div style={{display: 'flex', marginTop: '10px', justifyContent: 'space-around'}}>
                                    <TextField style={{width: '85px'}} type='number' size='small' variant='outlined'
                                               id='number'
                                               label='房间人数'
                                               defaultValue={4}
                                               inputProps={{min: 1}}
                                               onChange={(event) => setNumber(event.target.value)}
                                    />
                                    <Button style={{marginRight: '15px'}} size='small' type='submit' color='primary' variant='contained' onClick={handleCreateGame}>创建房间</Button>
                                </div>
                            </div>
                            <div className={classes.button}>
                                <Link to="/home">
                                    <Button type='submit' color='primary'>主页</Button>
                                </Link>
                            </div>
                            <div className={classes.button}>
                                <Link to="/home/rule">
                                    <Button type='submit' color='primary'>规则</Button>
                                </Link>
                            </div>
                            <div className={classes.button}>
                                <Link to="/home/market">
                                    <Button type='submit' color='primary'>市场预测</Button>
                                </Link>
                            </div>
                        </div>
                        <div className={classes.author}>
                            <Typography variant='subtitle2'>Author: LeZhou</Typography>
                            <Typography variant='subtitle2'>Email: lz25261n@pace.edu</Typography>
                            <div style={{display: 'flex'}}>
                                <Avatar alt='discrod' style={{height: 20, width: 20, marginRight: 5}} src={discord}/>
                                <Typography variant='subtitle2'>Official Discord</Typography>
                            </div>
                            <Typography variant='caption'>
                                <a href='https://discord.gg/FEucF3HHUj'>https://discord.gg/FEucF3HHUj</a>
                            </Typography>
                            <div style={{display: 'flex'}}>
                                <Avatar alt='kaihei' style={{height: 20, width: 20, marginRight: 5}} src={kaihei}/>
                                <Typography variant='subtitle2'>官方社区</Typography>
                            </div>
                            <Typography variant='caption'>
                                <a href='https://kaihei.co/1hgC0H'>https://kaihei.co/1hgC0H</a>
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={10}>
                    <Switch>
                        <Route path="/home" exact component={Rooms} />
                        <Route path="/home/rule" exact component={Rule} />
                        <Route path="/home/market" exact component={Market} />
                    </Switch>
                </Grid>
            </Grid>
        </div>
    )
}

export default Home;
