import {useEffect, useState} from "react";
import {getOrders} from "../../../Orders/Loadorder";
import {Paper} from "@material-ui/core";
import Plot from 'react-plotly.js';

const Market = () => {

    //index 0 1 2 3 4 5 => year1 year2 year3 year4 year5 year6
    const [P1_localMarket, setP1_localMarket] = useState([0,0,0,0,0,0]);
    const [P2_localMarket, setP2_localMarket] = useState([0,0,0,0,0,0]);
    const [P3_localMarket, setP3_localMarket] = useState([0,0,0,0,0,0]);
    const [P4_localMarket, setP4_localMarket] = useState([0,0,0,0,0,0]);

    const [P1_areaMarket, setP1_areaMarket] = useState([0,0,0,0,0,0]);
    const [P2_areaMarket, setP2_areaMarket] = useState([0,0,0,0,0,0]);
    const [P3_areaMarket, setP3_areaMarket] = useState([0,0,0,0,0,0]);
    const [P4_areaMarket, setP4_areaMarket] = useState([0,0,0,0,0,0]);

    const [P1_domesticMarket, setP1_domesticMarket] = useState([0,0,0,0,0,0]);
    const [P2_domesticMarket, setP2_domesticMarket] = useState([0,0,0,0,0,0]);
    const [P3_domesticMarket, setP3_domesticMarket] = useState([0,0,0,0,0,0]);
    const [P4_domesticMarket, setP4_domesticMarket] = useState([0,0,0,0,0,0]);

    const [P1_continentalMarket, setP1_continentalMarket] = useState([0,0,0,0,0,0]);
    const [P2_continentalMarket, setP2_continentalMarket] = useState([0,0,0,0,0,0]);
    const [P3_continentalMarket, setP3_continentalMarket] = useState([0,0,0,0,0,0]);
    const [P4_continentalMarket, setP4_continentalMarket] = useState([0,0,0,0,0,0]);

    const [P1_internationalMarket, setP1_internationalMarket] = useState([0,0,0,0,0,0]);
    const [P2_internationalMarket, setP2_internationalMarket] = useState([0,0,0,0,0,0]);
    const [P3_internationalMarket, setP3_internationalMarket] = useState([0,0,0,0,0,0]);
    const [P4_internationalMarket, setP4_internationalMarket] = useState([0,0,0,0,0,0]);

    // index 0 1 2 => '' 'ISO9000' 'ISO14000'
    const [P1_qualification_null, setP1_qualification_null] = useState([0,0,0,0,0,0]);
    const [P1_qualification_iso9000, setP1_qualification_iso9000] = useState([0,0,0,0,0,0]);
    const [P1_qualification_iso14000, setP1_qualification_iso14000] = useState([0,0,0,0,0,0]);

    const [P2_qualification_null, setP2_qualification_null] = useState([0,0,0,0,0,0]);
    const [P2_qualification_iso9000, setP2_qualification_iso9000] = useState([0,0,0,0,0,0]);
    const [P2_qualification_iso14000, setP2_qualification_iso14000] = useState([0,0,0,0,0,0]);

    const [P3_qualification_null, setP3_qualification_null] = useState([0,0,0,0,0,0]);
    const [P3_qualification_iso9000, setP3_qualification_iso9000] = useState([0,0,0,0,0,0]);
    const [P3_qualification_iso14000, setP3_qualification_iso14000] = useState([0,0,0,0,0,0]);

    const [P4_qualification_null, setP4_qualification_null] = useState([0,0,0,0,0,0]);
    const [P4_qualification_iso9000, setP4_qualification_iso9000] = useState([0,0,0,0,0,0]);
    const [P4_qualification_iso14000, setP4_qualification_iso14000] = useState([0,0,0,0,0,0]);

    // load orders from mongoDB
    useEffect(() => {
        LoadOrders();
    }, []);

    const LoadOrders = async () => {
        let res = await getOrders();
        if(res !== null){
            //local market
            const P1_1_local = res.filter(item => item.product === 'P1' && item.year === 1 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_local = res.filter(item => item.product === 'P1' && item.year === 2 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_local = res.filter(item => item.product === 'P1' && item.year === 3 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_local = res.filter(item => item.product === 'P1' && item.year === 4 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_local = res.filter(item => item.product === 'P1' && item.year === 5 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_local = res.filter(item => item.product === 'P1' && item.year === 6 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_localMarket([P1_1_local, P1_2_local, P1_3_local, P1_4_local, P1_5_local, P1_6_local]);

            const P2_1_local = res.filter(item => item.product === 'P2' && item.year === 1 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_local = res.filter(item => item.product === 'P2' && item.year === 2 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_local = res.filter(item => item.product === 'P2' && item.year === 3 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_local = res.filter(item => item.product === 'P2' && item.year === 4 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_local = res.filter(item => item.product === 'P2' && item.year === 5 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_local = res.filter(item => item.product === 'P2' && item.year === 6 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_localMarket([P2_1_local, P2_2_local, P2_3_local, P2_4_local, P2_5_local, P2_6_local]);

            const P3_1_local = res.filter(item => item.product === 'P3' && item.year === 1 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_local = res.filter(item => item.product === 'P3' && item.year === 2 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_local = res.filter(item => item.product === 'P3' && item.year === 3 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_local = res.filter(item => item.product === 'P3' && item.year === 4 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_local = res.filter(item => item.product === 'P3' && item.year === 5 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_local = res.filter(item => item.product === 'P3' && item.year === 6 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_localMarket([P3_1_local, P3_2_local, P3_3_local, P3_4_local, P3_5_local, P3_6_local]);

            const P4_1_local = res.filter(item => item.product === 'P4' && item.year === 1 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_local = res.filter(item => item.product === 'P4' && item.year === 2 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_local = res.filter(item => item.product === 'P4' && item.year === 3 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_local = res.filter(item => item.product === 'P4' && item.year === 4 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_local = res.filter(item => item.product === 'P4' && item.year === 5 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_local = res.filter(item => item.product === 'P4' && item.year === 6 && item.market === 'localMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_localMarket([P4_1_local, P4_2_local, P4_3_local, P4_4_local, P4_5_local, P4_6_local]);

            //area market
            const P1_1_area = res.filter(item => item.product === 'P1' && item.year === 1 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_area = res.filter(item => item.product === 'P1' && item.year === 2 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_area = res.filter(item => item.product === 'P1' && item.year === 3 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_area = res.filter(item => item.product === 'P1' && item.year === 4 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_area = res.filter(item => item.product === 'P1' && item.year === 5 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_area = res.filter(item => item.product === 'P1' && item.year === 6 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_areaMarket([P1_1_area, P1_2_area, P1_3_area, P1_4_area, P1_5_area, P1_6_area]);

            const P2_1_area = res.filter(item => item.product === 'P2' && item.year === 1 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_area = res.filter(item => item.product === 'P2' && item.year === 2 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_area = res.filter(item => item.product === 'P2' && item.year === 3 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_area = res.filter(item => item.product === 'P2' && item.year === 4 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_area = res.filter(item => item.product === 'P2' && item.year === 5 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_area = res.filter(item => item.product === 'P2' && item.year === 6 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_areaMarket([P2_1_area, P2_2_area, P2_3_area, P2_4_area, P2_5_area, P2_6_area]);

            const P3_1_area = res.filter(item => item.product === 'P3' && item.year === 1 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_area = res.filter(item => item.product === 'P3' && item.year === 2 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_area = res.filter(item => item.product === 'P3' && item.year === 3 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_area = res.filter(item => item.product === 'P3' && item.year === 4 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_area = res.filter(item => item.product === 'P3' && item.year === 5 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_area = res.filter(item => item.product === 'P3' && item.year === 6 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_areaMarket([P3_1_area, P3_2_area, P3_3_area, P3_4_area, P3_5_area, P3_6_area]);

            const P4_1_area = res.filter(item => item.product === 'P4' && item.year === 1 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_area = res.filter(item => item.product === 'P4' && item.year === 2 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_area = res.filter(item => item.product === 'P4' && item.year === 3 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_area = res.filter(item => item.product === 'P4' && item.year === 4 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_area = res.filter(item => item.product === 'P4' && item.year === 5 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_area = res.filter(item => item.product === 'P4' && item.year === 6 && item.market === 'areaMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_areaMarket([P4_1_area, P4_2_area, P4_3_area, P4_4_area, P4_5_area, P4_6_area]);

            //domestic market
            const P1_1_domestic = res.filter(item => item.product === 'P1' && item.year === 1 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_domestic = res.filter(item => item.product === 'P1' && item.year === 2 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_domestic = res.filter(item => item.product === 'P1' && item.year === 3 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_domestic = res.filter(item => item.product === 'P1' && item.year === 4 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_domestic = res.filter(item => item.product === 'P1' && item.year === 5 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_domestic = res.filter(item => item.product === 'P1' && item.year === 6 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_domesticMarket([P1_1_domestic, P1_2_domestic, P1_3_domestic, P1_4_domestic, P1_5_domestic, P1_6_domestic]);

            const P2_1_domestic = res.filter(item => item.product === 'P2' && item.year === 1 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_domestic = res.filter(item => item.product === 'P2' && item.year === 2 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_domestic = res.filter(item => item.product === 'P2' && item.year === 3 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_domestic = res.filter(item => item.product === 'P2' && item.year === 4 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_domestic = res.filter(item => item.product === 'P2' && item.year === 5 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_domestic = res.filter(item => item.product === 'P2' && item.year === 6 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_domesticMarket([P2_1_domestic, P2_2_domestic, P2_3_domestic, P2_4_domestic, P2_5_domestic, P2_6_domestic]);

            const P3_1_domestic = res.filter(item => item.product === 'P3' && item.year === 1 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_domestic = res.filter(item => item.product === 'P3' && item.year === 2 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_domestic = res.filter(item => item.product === 'P3' && item.year === 3 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_domestic = res.filter(item => item.product === 'P3' && item.year === 4 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_domestic = res.filter(item => item.product === 'P3' && item.year === 5 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_domestic = res.filter(item => item.product === 'P3' && item.year === 6 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_domesticMarket([P3_1_domestic, P3_2_domestic, P3_3_domestic, P3_4_domestic, P3_5_domestic, P3_6_domestic]);

            const P4_1_domestic = res.filter(item => item.product === 'P4' && item.year === 1 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_domestic = res.filter(item => item.product === 'P4' && item.year === 2 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_domestic = res.filter(item => item.product === 'P4' && item.year === 3 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_domestic = res.filter(item => item.product === 'P4' && item.year === 4 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_domestic = res.filter(item => item.product === 'P4' && item.year === 5 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_domestic = res.filter(item => item.product === 'P4' && item.year === 6 && item.market === 'domesticMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_domesticMarket([P4_1_domestic, P4_2_domestic, P4_3_domestic, P4_4_domestic, P4_5_domestic, P4_6_domestic]);

            //continental market
            const P1_1_continental = res.filter(item => item.product === 'P1' && item.year === 1 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_continental = res.filter(item => item.product === 'P1' && item.year === 2 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_continental = res.filter(item => item.product === 'P1' && item.year === 3 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_continental = res.filter(item => item.product === 'P1' && item.year === 4 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_continental = res.filter(item => item.product === 'P1' && item.year === 5 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_continental = res.filter(item => item.product === 'P1' && item.year === 6 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_continentalMarket([P1_1_continental, P1_2_continental, P1_3_continental, P1_4_continental, P1_5_continental, P1_6_continental]);

            const P2_1_continental = res.filter(item => item.product === 'P2' && item.year === 1 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_continental = res.filter(item => item.product === 'P2' && item.year === 2 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_continental = res.filter(item => item.product === 'P2' && item.year === 3 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_continental = res.filter(item => item.product === 'P2' && item.year === 4 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_continental = res.filter(item => item.product === 'P2' && item.year === 5 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_continental = res.filter(item => item.product === 'P2' && item.year === 6 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_continentalMarket([P2_1_continental, P2_2_continental, P2_3_continental, P2_4_continental, P2_5_continental, P2_6_continental]);

            const P3_1_continental = res.filter(item => item.product === 'P3' && item.year === 1 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_continental = res.filter(item => item.product === 'P3' && item.year === 2 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_continental = res.filter(item => item.product === 'P3' && item.year === 3 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_continental = res.filter(item => item.product === 'P3' && item.year === 4 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_continental = res.filter(item => item.product === 'P3' && item.year === 5 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_continental = res.filter(item => item.product === 'P3' && item.year === 6 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_continentalMarket([P3_1_continental, P3_2_continental, P3_3_continental, P3_4_continental, P3_5_continental, P3_6_continental]);

            const P4_1_continental = res.filter(item => item.product === 'P4' && item.year === 1 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_continental = res.filter(item => item.product === 'P4' && item.year === 2 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_continental = res.filter(item => item.product === 'P4' && item.year === 3 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_continental = res.filter(item => item.product === 'P4' && item.year === 4 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_continental = res.filter(item => item.product === 'P4' && item.year === 5 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_continental = res.filter(item => item.product === 'P4' && item.year === 6 && item.market === 'continentalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_continentalMarket([P4_1_continental, P4_2_continental, P4_3_continental, P4_4_continental, P4_5_continental, P4_6_continental]);

            //international market
            const P1_1_international = res.filter(item => item.product === 'P1' && item.year === 1 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_international = res.filter(item => item.product === 'P1' && item.year === 2 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_international = res.filter(item => item.product === 'P1' && item.year === 3 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_international = res.filter(item => item.product === 'P1' && item.year === 4 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_international = res.filter(item => item.product === 'P1' && item.year === 5 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_international = res.filter(item => item.product === 'P1' && item.year === 6 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_internationalMarket([P1_1_international, P1_2_international, P1_3_international, P1_4_international, P1_5_international, P1_6_international]);

            const P2_1_international = res.filter(item => item.product === 'P2' && item.year === 1 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_international = res.filter(item => item.product === 'P2' && item.year === 2 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_international = res.filter(item => item.product === 'P2' && item.year === 3 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_international = res.filter(item => item.product === 'P2' && item.year === 4 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_international = res.filter(item => item.product === 'P2' && item.year === 5 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_international = res.filter(item => item.product === 'P2' && item.year === 6 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_internationalMarket([P2_1_international, P2_2_international, P2_3_international, P2_4_international, P2_5_international, P2_6_international]);

            const P3_1_international = res.filter(item => item.product === 'P3' && item.year === 1 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_international = res.filter(item => item.product === 'P3' && item.year === 2 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_international = res.filter(item => item.product === 'P3' && item.year === 3 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_international = res.filter(item => item.product === 'P3' && item.year === 4 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_international = res.filter(item => item.product === 'P3' && item.year === 5 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_international = res.filter(item => item.product === 'P3' && item.year === 6 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_internationalMarket([P3_1_international, P3_2_international, P3_3_international, P3_4_international, P3_5_international, P3_6_international]);

            const P4_1_international = res.filter(item => item.product === 'P4' && item.year === 1 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_international = res.filter(item => item.product === 'P4' && item.year === 2 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_international = res.filter(item => item.product === 'P4' && item.year === 3 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_international = res.filter(item => item.product === 'P4' && item.year === 4 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_international = res.filter(item => item.product === 'P4' && item.year === 5 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_international = res.filter(item => item.product === 'P4' && item.year === 6 && item.market === 'internationalMarket').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_internationalMarket([P4_1_international, P4_2_international, P4_3_international, P4_4_international, P4_5_international, P4_6_international]);


            //qualification iso9000 iso14000
            const P1_1_null = res.filter(item => item.product === 'P1' && item.year === 1 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_null = res.filter(item => item.product === 'P1' && item.year === 2 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_null = res.filter(item => item.product === 'P1' && item.year === 3 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_null = res.filter(item => item.product === 'P1' && item.year === 4 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_null = res.filter(item => item.product === 'P1' && item.year === 5 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_null = res.filter(item => item.product === 'P1' && item.year === 6 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_qualification_null([P1_1_null, P1_2_null, P1_3_null, P1_4_null, P1_5_null, P1_6_null]);
            const P1_1_iso9000 = res.filter(item => item.product === 'P1' && item.year === 1 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_iso9000 = res.filter(item => item.product === 'P1' && item.year === 2 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_iso9000 = res.filter(item => item.product === 'P1' && item.year === 3 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_iso9000 = res.filter(item => item.product === 'P1' && item.year === 4 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_iso9000 = res.filter(item => item.product === 'P1' && item.year === 5 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_iso9000 = res.filter(item => item.product === 'P1' && item.year === 6 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_qualification_iso9000([P1_1_iso9000, P1_2_iso9000, P1_3_iso9000, P1_4_iso9000, P1_5_iso9000, P1_6_iso9000]);
            const P1_1_iso14000 = res.filter(item => item.product === 'P1' && item.year === 1 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_2_iso14000 = res.filter(item => item.product === 'P1' && item.year === 2 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_3_iso14000 = res.filter(item => item.product === 'P1' && item.year === 3 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_4_iso14000 = res.filter(item => item.product === 'P1' && item.year === 4 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_5_iso14000 = res.filter(item => item.product === 'P1' && item.year === 5 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P1_6_iso14000 = res.filter(item => item.product === 'P1' && item.year === 6 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP1_qualification_iso14000([P1_1_iso14000, P1_2_iso14000, P1_3_iso14000, P1_4_iso14000, P1_5_iso14000, P1_6_iso14000]);


            const P2_1_null = res.filter(item => item.product === 'P2' && item.year === 1 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_null = res.filter(item => item.product === 'P2' && item.year === 2 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_null = res.filter(item => item.product === 'P2' && item.year === 3 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_null = res.filter(item => item.product === 'P2' && item.year === 4 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_null = res.filter(item => item.product === 'P2' && item.year === 5 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_null = res.filter(item => item.product === 'P2' && item.year === 6 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_qualification_null([P2_1_null, P2_2_null, P2_3_null, P2_4_null, P2_5_null, P2_6_null]);
            const P2_1_iso9000 = res.filter(item => item.product === 'P2' && item.year === 1 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_iso9000 = res.filter(item => item.product === 'P2' && item.year === 2 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_iso9000 = res.filter(item => item.product === 'P2' && item.year === 3 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_iso9000 = res.filter(item => item.product === 'P2' && item.year === 4 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_iso9000 = res.filter(item => item.product === 'P2' && item.year === 5 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_iso9000 = res.filter(item => item.product === 'P2' && item.year === 6 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_qualification_iso9000([P2_1_iso9000, P2_2_iso9000, P2_3_iso9000, P2_4_iso9000, P2_5_iso9000, P2_6_iso9000]);
            const P2_1_iso14000 = res.filter(item => item.product === 'P2' && item.year === 1 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_2_iso14000 = res.filter(item => item.product === 'P2' && item.year === 2 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_3_iso14000 = res.filter(item => item.product === 'P2' && item.year === 3 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_4_iso14000 = res.filter(item => item.product === 'P2' && item.year === 4 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_5_iso14000 = res.filter(item => item.product === 'P2' && item.year === 5 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P2_6_iso14000 = res.filter(item => item.product === 'P2' && item.year === 6 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP2_qualification_iso14000([P2_1_iso14000, P2_2_iso14000, P2_3_iso14000, P2_4_iso14000, P2_5_iso14000, P2_6_iso14000]);


            const P3_1_null = res.filter(item => item.product === 'P3' && item.year === 1 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_null = res.filter(item => item.product === 'P3' && item.year === 2 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_null = res.filter(item => item.product === 'P3' && item.year === 3 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_null = res.filter(item => item.product === 'P3' && item.year === 4 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_null = res.filter(item => item.product === 'P3' && item.year === 5 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_null = res.filter(item => item.product === 'P3' && item.year === 6 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_qualification_null([P3_1_null, P3_2_null, P3_3_null, P3_4_null, P3_5_null, P3_6_null]);
            const P3_1_iso9000 = res.filter(item => item.product === 'P3' && item.year === 1 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_iso9000 = res.filter(item => item.product === 'P3' && item.year === 2 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_iso9000 = res.filter(item => item.product === 'P3' && item.year === 3 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_iso9000 = res.filter(item => item.product === 'P3' && item.year === 4 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_iso9000 = res.filter(item => item.product === 'P3' && item.year === 5 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_iso9000 = res.filter(item => item.product === 'P3' && item.year === 6 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_qualification_iso9000([P3_1_iso9000, P3_2_iso9000, P3_3_iso9000, P3_4_iso9000, P3_5_iso9000, P3_6_iso9000]);
            const P3_1_iso14000 = res.filter(item => item.product === 'P3' && item.year === 1 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_2_iso14000 = res.filter(item => item.product === 'P3' && item.year === 2 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_3_iso14000 = res.filter(item => item.product === 'P3' && item.year === 3 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_4_iso14000 = res.filter(item => item.product === 'P3' && item.year === 4 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_5_iso14000 = res.filter(item => item.product === 'P3' && item.year === 5 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P3_6_iso14000 = res.filter(item => item.product === 'P3' && item.year === 6 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP3_qualification_iso14000([P3_1_iso14000, P3_2_iso14000, P3_3_iso14000, P3_4_iso14000, P3_5_iso14000, P3_6_iso14000]);


            const P4_1_null = res.filter(item => item.product === 'P4' && item.year === 1 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_null = res.filter(item => item.product === 'P4' && item.year === 2 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_null = res.filter(item => item.product === 'P4' && item.year === 3 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_null = res.filter(item => item.product === 'P4' && item.year === 4 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_null = res.filter(item => item.product === 'P4' && item.year === 5 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_null = res.filter(item => item.product === 'P4' && item.year === 6 && item.iso === '').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_qualification_null([P4_1_null, P4_2_null, P4_3_null, P4_4_null, P4_5_null, P4_6_null]);
            const P4_1_iso9000 = res.filter(item => item.product === 'P4' && item.year === 1 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_iso9000 = res.filter(item => item.product === 'P4' && item.year === 2 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_iso9000 = res.filter(item => item.product === 'P4' && item.year === 3 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_iso9000 = res.filter(item => item.product === 'P4' && item.year === 4 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_iso9000 = res.filter(item => item.product === 'P4' && item.year === 5 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_iso9000 = res.filter(item => item.product === 'P4' && item.year === 6 && item.iso === 'ISO9000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_qualification_iso9000([P4_1_iso9000, P4_2_iso9000, P4_3_iso9000, P4_4_iso9000, P4_5_iso9000, P4_6_iso9000]);
            const P4_1_iso14000 = res.filter(item => item.product === 'P4' && item.year === 1 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_2_iso14000 = res.filter(item => item.product === 'P4' && item.year === 2 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_3_iso14000 = res.filter(item => item.product === 'P4' && item.year === 3 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_4_iso14000 = res.filter(item => item.product === 'P4' && item.year === 4 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_5_iso14000 = res.filter(item => item.product === 'P4' && item.year === 5 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            const P4_6_iso14000 = res.filter(item => item.product === 'P4' && item.year === 6 && item.iso === 'ISO14000').reduce((accumulator, item) => accumulator + item.amount, 0);
            setP4_qualification_iso14000([P4_1_iso14000, P4_2_iso14000, P4_3_iso14000, P4_4_iso14000, P4_5_iso14000, P4_6_iso14000]);

        }
    }

    return (
        <div style={{marginLeft: 5}}>
            <Paper>
                <div style={{marginLeft: 5}}>
                    {/*p1*/}
                    <Plot
                        data={[
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_localMarket,
                                name: 'lcoalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_areaMarket,
                                name: 'areaMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_domesticMarket,
                                name: 'domesticMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_continentalMarket,
                                name: 'continentalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_internationalMarket,
                                name: 'internationalMarket',
                                type: 'bar'
                            }
                        ]}
                        layout={ {width: 1000, height: 600, barmode: 'group',title: 'P1市场预测(需求量)'} }
                    />
                    <Plot
                        data={[
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_qualification_null,
                                mode: 'lines',
                                name: '不需要资格',
                                line: {
                                    color: 'rgb(51, 255, 51)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_qualification_iso9000,
                                mode: 'lines',
                                name: 'ISO9000',
                                line: {
                                    color: 'rgb(0, 128, 255)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P1_qualification_iso14000,
                                mode: 'lines',
                                name: 'ISO14000',
                                line: {
                                    color: 'rgb(255, 51, 51)',
                                    width: 2
                                }
                            }
                        ]}
                        layout={ {width: 1000, height: 600, title: 'P1市场预测(资格)'} }
                    />
                    {/*p2*/}
                    <Plot
                        data={[
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_localMarket,
                                name: 'lcoalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_areaMarket,
                                name: 'areaMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_domesticMarket,
                                name: 'domesticMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_continentalMarket,
                                name: 'continentalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_internationalMarket,
                                name: 'internationalMarket',
                                type: 'bar'
                            }
                        ]}
                        layout={ {width: 1000, height: 600, barmode: 'group',title: 'P2市场预测(需求量)'} }
                    />
                    <Plot
                        data={[
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_qualification_null,
                                mode: 'lines',
                                name: '不需要资格',
                                line: {
                                    color: 'rgb(51, 255, 51)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_qualification_iso9000,
                                mode: 'lines',
                                name: 'ISO9000',
                                line: {
                                    color: 'rgb(0, 128, 255)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P2_qualification_iso14000,
                                mode: 'lines',
                                name: 'ISO14000',
                                line: {
                                    color: 'rgb(255, 51, 51)',
                                    width: 2
                                }
                            }
                        ]}
                        layout={ {width: 1000, height: 600, title: 'P2市场预测(资格)'} }
                    />
                    {/*p3*/}
                    <Plot
                        data={[
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_localMarket,
                                name: 'lcoalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_areaMarket,
                                name: 'areaMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_domesticMarket,
                                name: 'domesticMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_continentalMarket,
                                name: 'continentalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_internationalMarket,
                                name: 'internationalMarket',
                                type: 'bar'
                            }
                        ]}
                        layout={ {width: 1000, height: 600, barmode: 'group',title: 'P3市场预测(需求量)'} }
                    />
                    <Plot
                        data={[
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_qualification_null,
                                mode: 'lines',
                                name: '不需要资格',
                                line: {
                                    color: 'rgb(51, 255, 51)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_qualification_iso9000,
                                mode: 'lines',
                                name: 'ISO9000',
                                line: {
                                    color: 'rgb(0, 128, 255)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P3_qualification_iso14000,
                                mode: 'lines',
                                name: 'ISO14000',
                                line: {
                                    color: 'rgb(255, 51, 51)',
                                    width: 2
                                }
                            }
                        ]}
                        layout={ {width: 1000, height: 600, title: 'P3市场预测(资格)'} }
                    />
                    {/*p4*/}
                    <Plot
                        data={[
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_localMarket,
                                name: 'lcoalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_areaMarket,
                                name: 'areaMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_domesticMarket,
                                name: 'domesticMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_continentalMarket,
                                name: 'continentalMarket',
                                type: 'bar'
                            },
                            {
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_internationalMarket,
                                name: 'internationalMarket',
                                type: 'bar'
                            }
                        ]}
                        layout={ {width: 1000, height: 600, barmode: 'group',title: 'P4市场预测(需求量)'} }
                    />
                    <Plot
                        data={[
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_qualification_null,
                                mode: 'lines',
                                name: '不需要资格',
                                line: {
                                    color: 'rgb(51, 255, 51)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_qualification_iso9000,
                                mode: 'lines',
                                name: 'ISO9000',
                                line: {
                                    color: 'rgb(0, 128, 255)',
                                    width: 2
                                }
                            },
                            {
                                type: 'scatter',
                                x: ['第一年', '第二年', '第三年', '第四年', '第五年', '第六年'],
                                y: P4_qualification_iso14000,
                                mode: 'lines',
                                name: 'ISO14000',
                                line: {
                                    color: 'rgb(255, 51, 51)',
                                    width: 2
                                }
                            }
                        ]}
                        layout={ {width: 1000, height: 600, title: 'P4市场预测(资格)'} }
                    />
                </div>
            </Paper>
        </div>
    )
}

export default Market;
