import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch} from "react-redux";

export default function Fbutton(props) {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlerentsmall = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'rent',
                size: 'small',
            }});
    };
    const handlerentmiddle = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'rent',
                size: 'middle',
            }});
    };
    const handlerentlarge = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'rent',
                size: 'large',
            }});
    };
    const handlebuysmall = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'buy',
                size: 'small',
            }});
    };
    const handlebuymiddle = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'buy',
                size: 'middle',
            }});
    };
    const handlebuylarge = () => {
        setAnchorEl(null);
        dispatch({type: 'FACTORY', payload: {
                factory: props.name,
                property: 'buy',
                size: 'large',
            }});
    };

    return (
        <div>
            <Button color='primary' variant='contained' aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
                空地(厂房)
            </Button>
            <Menu
                id='F1-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handlerentsmall}>租用小厂房(租金18/年)</MenuItem>
                <MenuItem onClick={handlerentmiddle}>租用中厂房(租金30/年)</MenuItem>
                <MenuItem onClick={handlerentlarge}>租用大厂房(租金44/年)</MenuItem>
                <MenuItem onClick={handlebuysmall}>购买小厂房(金额:180)</MenuItem>
                <MenuItem onClick={handlebuymiddle}>购买中厂房(金额:300)</MenuItem>
                <MenuItem onClick={handlebuylarge}>购买大厂房(金额:440)</MenuItem>
            </Menu>
        </div>
    );
}
