import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    button: {
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        border: '1px solid black',
        borderRadius: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#D3D3D3',
        marginRight: '3px'
    },
    factory: {
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        border: '1px solid black',
        borderRadius: '5px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        marginRight: '3px'
    },

}));
