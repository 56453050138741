import initalstate from "./initalstate";

function financeReducer(state = initalstate, action) {
    switch (action.type) {
        case 'BUY':
            let expense_buy = 0;
            if(action.payload.condition === 'normal')
                expense_buy = action.payload.amount * state.Finance[action.payload.kind].normalPrice;
            else if(action.payload.condition === 'urgent')
                expense_buy = action.payload.amount * state.Finance[action.payload.kind].urgentPrice;

            if(state.Cash - expense_buy > 0){
                if(action.payload.condition === 'normal')
                    return {
                        ...state,
                        Cash: state.Cash - expense_buy,
                        [action.payload.kind]: {
                            ...state[action.payload.kind],
                            transit: state[action.payload.kind].transit + action.payload.amount
                        },
                    };
                else if(action.payload.condition === 'urgent')
                    return {
                        ...state,
                        Cash: state.Cash - expense_buy,
                        [action.payload.kind]: {
                            ...state[action.payload.kind],
                            currentStock: state[action.payload.kind].currentStock + action.payload.amount
                        },
                    };
            }
            else
                return state;

        case 'FACTORY':
            let factory_expense = 0;
            let factory_space = [];
            let rent_factory = 0;
            if(action.payload.property === 'rent' && action.payload.size === 'small'){
                factory_expense = state.Finance.Factory.small.rent;
                rent_factory = state.Finance.Factory.small.rent;
                factory_space = state.Finance.Factory.small.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'middle'){
                factory_expense = state.Finance.Factory.middle.rent;
                rent_factory = state.Finance.Factory.middle.rent;
                factory_space = state.Finance.Factory.middle.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'large'){
                factory_expense = state.Finance.Factory.large.rent;
                rent_factory = state.Finance.Factory.large.rent;
                factory_space = state.Finance.Factory.large.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'small'){
                factory_expense = state.Finance.Factory.small.buy;
                factory_space = state.Finance.Factory.small.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'middle'){
                factory_expense = state.Finance.Factory.middle.buy;
                factory_space = state.Finance.Factory.middle.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'large'){
                factory_expense = state.Finance.Factory.large.buy;
                factory_space = state.Finance.Factory.large.space;
            }
            if(state.Cash - factory_expense > 0)
                return {
                    ...state,
                    Cash: state.Cash - factory_expense,
                    Rent: {
                        ...state.Rent,
                        factory: state.Rent.factory + rent_factory,
                    },
                    [action.payload.factory]: {
                        ...state[action.payload.factory],
                        expense: factory_expense,
                        property: action.payload.property,
                        size: action.payload.size,
                        line: factory_space
                    }
                };
            else
                return state;
        // case 'CHANGEFACTORY':

        case 'STORE':
            let store_expense = 0;
            let store_space = 0;
            let rent_store = 0;
            if(action.payload.property === 'rent' && action.payload.size === 'small'){
                store_expense = state.Finance.Store.small.rent;
                rent_store = state.Finance.Store.small.rent;
                store_space = state.Finance.Store.small.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'middle'){
                store_expense = state.Finance.Store.middle.rent;
                rent_store = state.Finance.Store.middle.rent;
                store_space = state.Finance.Store.middle.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'large'){
                store_expense = state.Finance.Store.large.rent;
                rent_store = state.Finance.Store.large.rent;
                store_space = state.Finance.Store.large.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'small'){
                store_expense = state.Finance.Store.small.buy;
                store_space = state.Finance.Store.small.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'middle'){
                store_expense = state.Finance.Store.middle.buy;
                store_space = state.Finance.Store.middle.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'large'){
                store_expense = state.Finance.Store.large.buy;
                store_space = state.Finance.Store.large.space;
            }

            if(state.Cash - store_expense > 0)
                return {
                    ...state,
                    Cash: state.Cash - store_expense,
                    Rent: {
                        ...state.Rent,
                        store: state.Rent.store + rent_store,
                    },
                    storehouse: {
                        ...state.storehouse,
                        expense: store_expense,
                        property: action.payload.property,
                        size: action.payload.size,
                        storage: store_space
                    }
                };
            else
                return state

        case 'changeStore':
            let changeStore_rent = 0;
            let changeStore_buy = 0;
            let changeStore_space = 0;
            let previousRent = state.storehouse.property === 'rent' ? state.storehouse.expense : 0;
            let previousBuy = state.storehouse.property === 'buy' ? state.storehouse.expense : 0;
            if(action.payload.property === 'rent' && action.payload.size === 'small'){
                changeStore_rent = state.Finance.Store.small.rent;
                changeStore_space = state.Finance.Store.small.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'middle'){
                changeStore_rent = state.Finance.Store.middle.rent;
                changeStore_space = state.Finance.Store.middle.space;
            }
            else if(action.payload.property === 'rent' && action.payload.size === 'large'){
                changeStore_rent = state.Finance.Store.large.rent;
                changeStore_space = state.Finance.Store.large.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'small'){
                changeStore_buy = state.Finance.Store.small.buy;
                changeStore_space = state.Finance.Store.small.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'middle'){
                changeStore_buy = state.Finance.Store.middle.buy;
                changeStore_space = state.Finance.Store.middle.space;
            }
            else if(action.payload.property === 'buy' && action.payload.size === 'large'){
                changeStore_buy = state.Finance.Store.large.buy;
                changeStore_space = state.Finance.Store.large.space;
            }

            if(action.payload.property === 'buy'){
                if(state.Cash - changeStore_buy > 0)
                    return {
                        ...state,
                        Cash: state.Cash - changeStore_buy + previousBuy,
                        Rent: {
                            ...state.Rent,
                            store: state.Rent.store + changeStore_rent - previousRent,
                        },
                        storehouse: {
                            ...state.storehouse,
                            expense: changeStore_buy,
                            property: action.payload.property,
                            size: action.payload.size,
                            storage: changeStore_space
                        }
                    };
                else
                    return state
            }
            else{
                //update new rent
                return {
                    ...state,
                    Cash: state.Cash + previousBuy,
                    Rent: {
                        ...state.Rent,
                        store: state.Rent.store + changeStore_rent - previousRent,
                    },
                    storehouse: {
                        ...state.storehouse,
                        expense: changeStore_rent,
                        property: action.payload.property,
                        size: action.payload.size,
                        storage: changeStore_space
                    }
                };
            }

        case 'CLEAR':
            let r1_clear = 0;
            let r2_clear = 0;
            let r3_clear = 0;
            let r4_clear = 0;
            if(action.payload.product === 'R1')
                r1_clear = 1;
            else if(action.payload.product === 'R2')
                r2_clear = 1;
            else if(action.payload.product === 'R3')
                r3_clear = 1;
            else if(action.payload.product === 'R4')
                r4_clear = 1;
            return {
                ...state,
                Cash: state.Cash + 10,
                R1: {
                    ...state.R1,
                    currentStock: state.R1.currentStock - r1_clear
                },
                R2: {
                    ...state.R2,
                    currentStock: state.R2.currentStock - r2_clear
                },
                R3: {
                    ...state.R3,
                    currentStock: state.R3.currentStock - r3_clear
                },
                R4: {
                    ...state.R4,
                    currentStock: state.R4.currentStock - r4_clear
                }
            }
        case 'NEWLINE':
            let NewLine = {};
            NewLine.producttype = action.payload.product;
            NewLine.linetype = action.payload.lineType;
            let MaintainFee_newLine = 0;
            if(NewLine.linetype === 'handmade'){
                NewLine.state = 'complete';
                // NewLine.progress = state.Finance.handmadeline.produceTerm;
                NewLine.progress = [false, false];
                NewLine.expense = state.Finance.handmadeline.expense;
                NewLine.depreciation = [10,10,10,5];
                MaintainFee_newLine = 10;
            }
            else if(NewLine.linetype === 'automatic'){
                NewLine.state = 'building';
                NewLine.progress = [true, false, false];
                NewLine.expense = 50;
                NewLine.depreciation = [];
            }
            else if(NewLine.linetype === 'flexible'){
                NewLine.state = 'building';
                NewLine.progress = [true, false, false, false];
                NewLine.expense = 50;
                NewLine.depreciation = [];
            }

            let Newlines = [...state[action.payload.factory].line];
            let index = Newlines.findIndex(item => Object.keys(item).length === 0);
            Newlines[index] = NewLine;

            if(state.Cash - NewLine.expense > 0 && index !== -1){
                return {
                    ...state,
                    ClickonceEachTerm: {
                        ...state.ClickonceEachTerm,
                        Building_Produce: [
                            ...state.ClickonceEachTerm.Building_Produce,
                            {
                                factory: action.payload.factory,
                                index: index
                            }
                        ]
                    },
                    Cash: state.Cash - NewLine.expense,
                    MaintainFee: state.MaintainFee + MaintainFee_newLine,
                    [action.payload.factory]: {
                        ...state[action.payload.factory],
                        line: Newlines
                    }
                };
            }
            else
                return state;
        case 'BUILDING':
            let building_lines = [...state[action.payload.factory].line];
            let expense_build = 0;
            building_lines.map((item, index) => {
                if(index === action.payload.index){
                    let progress_index = item.progress.findIndex(item => !item);
                    if(progress_index !== -1 && item.state === 'building'){
                        item.progress[progress_index] = true;
                        if(item.linetype === 'automatic'){
                            expense_build = 50;
                            item.expense += expense_build
                        }
                        else if(item.linetype === 'flexible'){
                            expense_build = 50;
                            item.expense += expense_build
                        }
                    }
                };
            });
            return {
                ...state,
                ClickonceEachTerm: {
                    ...state.ClickonceEachTerm,
                    Building_Produce: [
                        ...state.ClickonceEachTerm.Building_Produce,
                        {
                            factory: action.payload.factory,
                            index: action.payload.index
                        }
                    ]
                },
                Cash: state.Cash - expense_build,
                [action.payload.factory]: {
                    ...state[action.payload.factory],
                    line: building_lines
                }
            };
        case 'DISASSEMBLE':
            let lines_disassemble = [...state[action.payload.factory].line];
            console.log(lines_disassemble[action.payload.index]);
            let salvage_disassemble = lines_disassemble[action.payload.index].depreciation.reduce((a,b) => a+ b, 0);
            lines_disassemble.splice(action.payload.index, 1, {});
            return {
                ...state,
                Cash: state.Cash + salvage_disassemble,
                [action.payload.factory]:{
                    ...state[action.payload.factory],
                    line: lines_disassemble
                }
            };
        case 'PRODUCE':
            let produce_lines = [...state[action.payload.factory].line];
            let expense_produce = 0;
            let expense_material = 0;

            let updateR1_currentStock = state.R1.currentStock;
            let updateR2_currentStock = state.R2.currentStock;
            let updateR3_currentStock = state.R3.currentStock;
            let updateR4_currentStock = state.R4.currentStock;
            let updateP1_currentStock = state.P1.currentStock;
            produce_lines.map((item, index) => {
                if(index === action.payload.index){
                    let produce_progress_index = item.progress.findIndex(item => !item);
                    if(produce_progress_index !== -1 && item.state === 'complete'){
                        if(item.progress[0]){
                            item.progress[produce_progress_index] = true;
                        }
                        else{
                            if(action.payload.product_type === 'P1'){
                                if(state.Cash - state.Finance.processingExpense > 0 && state.R1.currentStock >0){
                                    item.progress[produce_progress_index] = true;
                                    expense_produce = state.Finance.processingExpense;
                                    expense_material = 10;
                                    updateR1_currentStock = state.R1.currentStock -1;
                                }
                            }
                            else if(action.payload.product_type === 'P2'){
                                if(state.Cash - state.Finance.processingExpense > 0
                                    && state.R2.currentStock > 0
                                    && state.R3.currentStock > 0){
                                    item.progress[produce_progress_index] = true;
                                    expense_produce = state.Finance.processingExpense;
                                    expense_material = 20;
                                    updateR2_currentStock = state.R2.currentStock - 1;
                                    updateR3_currentStock = state.R3.currentStock - 1;
                                }
                            }
                            else if(action.payload.product_type === 'P3'){
                                if(state.Cash - state.Finance.processingExpense > 0
                                    && state.R3.currentStock > 0
                                    && state.R4.currentStock > 0){
                                    item.progress[produce_progress_index] = true;
                                    expense_produce = state.Finance.processingExpense;
                                    expense_material = 20;
                                    updateR3_currentStock = state.R3.currentStock - 1;
                                    updateR4_currentStock = state.R4.currentStock - 1;
                                }
                            }
                            else if(action.payload.product_type === 'P4'){
                                if(state.Cash - state.Finance.processingExpense > 0
                                    && state.R2.currentStock > 0
                                    && state.R4.currentStock > 0
                                    && state.P1.currentStock > 0){
                                    item.progress[produce_progress_index] = true;
                                    expense_produce = state.Finance.processingExpense;
                                    expense_material = 40;
                                    updateR2_currentStock = state.R2.currentStock - 1;
                                    updateR4_currentStock = state.R4.currentStock - 1;
                                    updateP1_currentStock = state.P1.currentStock - 1;
                                }
                            }
                        }
                    }
                };
            });
            return {
                ...state,
                ClickonceEachTerm: {
                    ...state.ClickonceEachTerm,
                    Building_Produce: [
                        ...state.ClickonceEachTerm.Building_Produce,
                        {
                            factory: action.payload.factory,
                            index: action.payload.index
                        }
                    ]
                },
                Cash: state.Cash - expense_produce,
                In_processing: state.In_processing + expense_material + expense_produce,
                R1: {
                    ...state.R1,
                    currentStock: updateR1_currentStock
                },
                R2: {
                    ...state.R2,
                    currentStock: updateR2_currentStock
                },
                R3: {
                    ...state.R3,
                    currentStock: updateR3_currentStock
                },
                R4: {
                    ...state.R4,
                    currentStock: updateR4_currentStock
                },
                P1: {
                    ...state.P1,
                    currentStock: updateP1_currentStock
                },
                [action.payload.factory]: {
                    ...state[action.payload.factory],
                    line: produce_lines
                }
            };

        case 'CHANGEPRODUCT':
            let change_expense = 0
            let newArray = [...state[action.payload.factory].line];
            newArray.map((item, index) => {
                if(index === action.payload.index){
                    item.producttype = action.payload.product;
                    if(item.linetype === 'automatic'){
                        change_expense = 20;
                    }
                }
            });
            return {
                ...state,
                ClickonceEachTerm: {
                    ...state.ClickonceEachTerm,
                    Building_Produce: [
                        ...state.ClickonceEachTerm.Building_Produce,
                        {
                            factory: action.payload.factory,
                            index: action.payload.index
                        }
                    ]
                },
                Cash: state.Cash - change_expense,
                ChangeCost: state.ChangeCost + change_expense,
                [action.payload.factory]: {
                    ...state[action.payload.factory],
                    line: newArray
                }
            }

        case 'DEVELOP':
            let newprogress = [...state[action.payload].progress];
            let newdevelop = state[action.payload].develop;
            let index_develop = newprogress.findIndex(item => item === 0);
            let expense_develop = 0;
            if(action.payload === 'P1')
                expense_develop = 10;
            else if(action.payload === 'P2')
                expense_develop = 10;
            else if(action.payload === 'P3')
                expense_develop = 10;
            else if(action.payload === 'P4')
                expense_develop = 10;
            else if(action.payload === 'ISO9000')
                expense_develop = 20;
            else if(action.payload === 'ISO14000')
                expense_develop = 20;
            else if(action.payload === 'localMarket')
                expense_develop = 10;
            else if(action.payload === 'areaMarket')
                expense_develop = 10;
            else if(action.payload === 'domesticMarket')
                expense_develop = 10;
            else if(action.payload === 'continentalMarket')
                expense_develop = 10;
            else if(action.payload === 'internationalMarket')
                expense_develop = 10;
            if(index_develop !== -1)
                newprogress[index_develop] = expense_develop;
            if(newprogress[newprogress.length - 1] !== 0)
                newdevelop = true;

            if(!state[action.payload].develop)
                return {
                    ...state,
                    ClickonceEachTerm: {
                        ...state.ClickonceEachTerm,
                        Develop: [
                            ...state.ClickonceEachTerm.Develop,
                            action.payload
                        ]
                    },
                    Cash: state.Cash - expense_develop,
                    DevelopFee: state.DevelopFee + expense_develop,
                    [action.payload]:{
                        ...state[action.payload],
                        develop: newdevelop,
                        progress: newprogress
                    }
                };
            else
                return state;

        case 'NEWLONGLOAN':
            let repay = [];
            let L_money = Number(action.payload.number);
            for(let i = 1; i <= action.payload.year; i++){
                if(i === action.payload.year)
                    repay.push(Number((L_money * 1.1).toFixed(0)))
                else
                    repay.push(Number((L_money * 0.1).toFixed(0)))
            }
            const newloan = {
                amount: L_money,
                year: action.payload.year,
                repay: [...repay]
            };
            return {
                ...state,
                Cash: state.Cash + L_money,
                LongTermLoan: [
                    ...state.LongTermLoan,
                    newloan
                ]
            };
        case 'NEWSHORTLOAN':
            let short_loanrepay = [];
            let S_money = Number(action.payload.number);
            //4 quarter -> after 1 year
            for(let i = 1; i <= 4; i++){
                if(i === 4)
                    short_loanrepay.push(Number((S_money * 1.05).toFixed(0)))
                else
                    short_loanrepay.push(0);
            }
            const newshortloan = {
                amount: S_money,
                repay: [...short_loanrepay]
            };
            return {
                ...state,
                Cash: state.Cash + S_money,
                ShortTermLoan: [
                    ...state.ShortTermLoan,
                    newshortloan
                ]
            };
        case 'PAYORDER':
            const newReceivables = state.receivableAccounts;
            const newOrder = state.Order;
            const theOrder = newOrder[action.payload.order_index];
            let p1 = 0;
            let p2 = 0;
            let p3 = 0;
            let p4 = 0;
            if(state[theOrder.product].currentStock > theOrder.amount){
                newReceivables.push(theOrder);
                newOrder.splice(action.payload.order_index, 1);
                if(theOrder.product === 'P1')
                    p1 = theOrder.amount;
                else if(theOrder.product === 'P2')
                    p2 = theOrder.amount;
                else if(theOrder.product === 'P3')
                    p3 = theOrder.amount;
                else if(theOrder.product === 'P4')
                    p4 = theOrder.amount;
            }

            return {
                ...state,
                receivableAccounts: newReceivables,
                Order: newOrder,
                P1: {
                    ...state.P1,
                    currentStock: state.P1.currentStock - p1
                },
                P2: {
                    ...state.P2,
                    currentStock: state.P2.currentStock - p2
                },
                P3: {
                    ...state.P3,
                    currentStock: state.P3.currentStock - p3
                },
                P4: {
                    ...state.P4,
                    currentStock: state.P4.currentStock - p4
                }

            }
        case 'DISCOUNT':
            let Discount_money = Number(action.payload.money);
            let theReceivable = state.receivableAccounts[action.payload.index];
            theReceivable.price = theReceivable.price - Discount_money;
            let arrivalMoney_Discount = 0
            if(theReceivable.revenuetime <= 2)
                arrivalMoney_Discount = Number.parseInt((Discount_money * (1 -0.1)).toFixed(0))
            else
                arrivalMoney_Discount = Number.parseInt((Discount_money * (1 - 0.125)).toFixed(0))
            let financialCost_discount = Discount_money - arrivalMoney_Discount;
            console.log('checkDISCOUNT', Discount_money, theReceivable, arrivalMoney_Discount, financialCost_discount)
            return {
                ...state,
                Cash: state.Cash + arrivalMoney_Discount,
                Income: state.Income + arrivalMoney_Discount,
                FinancialCost: state.FinancialCost + financialCost_discount
            };
        case 'LOADORDER':
            const Orders = [];
            if(action.payload.order.length !== 0){
                action.payload.order.map(item => {
                    let order = {
                        product: item.product,
                        amount: item.amount,
                        price: item.price,
                        due: item.due,
                        revenuetime: item.arrivalTime,
                        cost: item.cost
                    };
                    Orders.push(order);
                })
            }
            const newOrders = state.Order.concat(Orders);
            return {
                ...state,
                Order: newOrders
            }
        case 'NEXTROUND':
            //update time
            let newquarter = state.Quarter;
            if(newquarter === 4){
                newquarter = 1;
            }
            else
                newquarter += 1;

            //update line to complete produce
            let lines_factory1 = state.factory1.line;
            let lines_factory2 = state.factory2.line;
            let lines_factory3 = state.factory3.line;
            let p1_produce = 0;
            let p2_produce = 0;
            let p3_produce = 0;
            let p4_produce = 0;
            //维护费
            let array_maintainFee_newLine = [];

            lines_factory1.map((item) => {
                if(Object.keys(item).length !== 0){
                    if(item.progress[item.progress.length - 1]){
                        if(item.linetype === 'handmade'){
                            item.progress = [false,false];
                            if(item.producttype === 'P1')
                                p1_produce++;
                            else if(item.producttype === 'P2')
                                p2_produce++;
                            else if(item.producttype === 'P3')
                                p3_produce++;
                            else
                                p4_produce++;
                        }
                        else if(item.linetype === 'automatic'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [30,30,30,30,30];
                                array_maintainFee_newLine.push(20);
                            }
                        }
                        else if(item.linetype === 'flexible'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [40,40,40,40,40];
                                array_maintainFee_newLine.push(30);
                            }
                        }
                    }
                }
            });

            lines_factory2.map((item) => {
                if(Object.keys(item).length !== 0){
                    if(item.progress[item.progress.length - 1]){
                        if(item.linetype === 'handmade'){
                            item.progress = [false,false];
                            if(item.producttype === 'P1')
                                p1_produce++;
                            else if(item.producttype === 'P2')
                                p2_produce++;
                            else if(item.producttype === 'P3')
                                p3_produce++;
                            else
                                p4_produce++;
                        }
                        else if(item.linetype === 'automatic'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [30,30,30,30,30];
                                array_maintainFee_newLine.push(20);
                            }
                        }
                        else if(item.linetype === 'flexible'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [40,40,40,40,40];
                                array_maintainFee_newLine.push(30);
                            }
                        }
                    }
                }
            });

            lines_factory3.map((item) => {
                if(Object.keys(item).length !== 0){
                    if(item.progress[item.progress.length - 1]){
                        if(item.linetype === 'handmade'){
                            item.progress = [false,false];
                            if(item.producttype === 'P1')
                                p1_produce++;
                            else if(item.producttype === 'P2')
                                p2_produce++;
                            else if(item.producttype === 'P3')
                                p3_produce++;
                            else
                                p4_produce++;
                        }
                        else if(item.linetype === 'automatic'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [30,30,30,30,30];
                                array_maintainFee_newLine.push(20);
                            }
                        }
                        else if(item.linetype === 'flexible'){
                            if(item.state === 'complete'){
                                item.progress = [false];
                                if(item.producttype === 'P1')
                                    p1_produce++;
                                else if(item.producttype === 'P2')
                                    p2_produce++;
                                else if(item.producttype === 'P3')
                                    p3_produce++;
                                else
                                    p4_produce++;
                            }
                            else{
                                item.progress = [false];
                                item.state = 'complete';
                                item.depreciation = [40,40,40,40,40];
                                array_maintainFee_newLine.push(30);
                            }
                        }
                    }
                }
            });

            //load short loan
            let shortloan = state.ShortTermLoan;
            let repayshortloan = 0;
            let interest_shortloan = 0;
            shortloan.map(item => {
                if(item.repay.length === 1){
                    let shortloan_final = item.repay.shift();
                    repayshortloan = repayshortloan + shortloan_final;
                    interest_shortloan = interest_shortloan + (shortloan_final - item.amount);
                }
                else
                    item.repay.shift();
            });
            let newshortLoan = shortloan.filter(item => item.repay.length >= 1);

            //update receivables
            let receivables = state.receivableAccounts;
            let arrive_Cash = 0;
            let income_receivables = 0;
            let cost_receivables = 0;
            receivables.map(item => {
                if(item.revenuetime === 1){
                    arrive_Cash = arrive_Cash + item.price;
                    item.revenuetime = item.revenuetime - 1;
                    //update income and cost from receivables
                    income_receivables = income_receivables + item.price;
                    cost_receivables = cost_receivables + item.cost;
                }
                else
                    item.revenuetime = item.revenuetime - 1;
            });
            let receivables_ArrivingCash = receivables.filter(item => item.revenuetime > 0);

            //update order for liquidated damages
            let order_overtime = state.Order;
            let newOrder_overtime = state.Order;
            let liquidatedDamages = 0;
            order_overtime.map((item,index) => {
                if(item.due < newquarter){
                    liquidatedDamages = liquidatedDamages + Number.parseInt((item.price * 0.2).toFixed(0));
                    newOrder_overtime.splice(index, 1);
                }
                //delete overtime when through next years
                if(item.due === 4 && state.Quarter === 4){
                    liquidatedDamages = liquidatedDamages + Number.parseInt((item.price * 0.2).toFixed(0));
                    newOrder_overtime.splice(index, 1);
                }
            })

            return {
                ...state,
                ClickonceEachTerm: {
                    Building_Produce: [],
                    Develop: []
                },
                Quarter: newquarter,

                //updata factory
                factory1: {
                    ...state.factory1,
                    line: lines_factory1
                },
                factory2: {
                    ...state.factory2,
                    line: lines_factory2
                },
                factory3: {
                    ...state.factory3,
                    line: lines_factory3
                },

                //update stock
                R1: {
                    ...state.R1,
                    currentStock: state.R1.currentStock + state.R1.transit,
                    transit: 0
                },
                R2: {
                    ...state.R2,
                    currentStock: state.R2.currentStock + state.R2.transit,
                    transit: 0
                },
                R3: {
                    ...state.R3,
                    currentStock: state.R3.currentStock + state.R3.transit,
                    transit: 0
                },
                R4: {
                    ...state.R4,
                    currentStock: state.R4.currentStock + state.R4.transit,
                    transit: 0
                },
                P1: {
                    ...state.P1,
                    currentStock: state.P1.currentStock + state.P1.transit + p1_produce,
                    transit: 0
                },
                P2: {
                    ...state.P2,
                    currentStock: state.P2.currentStock + state.P2.transit + p2_produce,
                    transit: 0
                },
                P3: {
                    ...state.P3,
                    currentStock: state.P3.currentStock + state.P3.transit + p3_produce,
                    transit: 0
                },
                P4: {
                    ...state.P4,
                    currentStock: state.P4.currentStock + state.P4.transit + p4_produce,
                    transit: 0
                },

                //In_processing to goods
                In_processing: state.In_processing
                    - p1_produce * 20
                    - p2_produce * 30
                    - p3_produce * 30
                    - p4_produce * 40,

                // managementFee for each quater
                ManagementFee: state.ManagementFee + 20,

                //update maintainfee_newlines for each year, which is a array for all lines
                MaintainFee: state.MaintainFee + array_maintainFee_newLine.reduce((a,b)=> a+b,0),

                //repay short loan and receivable 违约金 管理费
                Cash: state.Cash - repayshortloan + arrive_Cash - liquidatedDamages - 20 - array_maintainFee_newLine.reduce((a,b)=> a+b,0),
                FinancialCost: state.FinancialCost + interest_shortloan + liquidatedDamages,
                ShortTermLoan: newshortLoan,

                //update income and cost
                Income: state.Income + income_receivables,
                Cost: state.Cost + cost_receivables,

                //receivable to cash
                receivableAccounts: receivables_ArrivingCash,

                //update order
                Order: [
                    ...newOrder_overtime
                ]
            };

        case 'NEWYEAR':
            //lines_depreciation
            let lines_depreciation_factory1 = state.factory1.line;
            let lines_depreciation_factory2 = state.factory2.line;
            let lines_depreciation_factory3 = state.factory3.line;
            let depreciation_factory1 = 0;
            let depreciation_factory2 = 0;
            let depreciation_factory3 = 0;
            lines_depreciation_factory1.map(item => {
                if(Object.keys(item).length !== 0){
                    if(item.state === 'complete' && item.depreciation.length > 1){
                        depreciation_factory1 = depreciation_factory1 + item.depreciation.shift();
                    }
                }
            });
            lines_depreciation_factory2.map(item => {
                if(Object.keys(item).length !== 0){
                    if(item.state === 'complete' && item.depreciation.length > 1){
                        depreciation_factory2 = depreciation_factory2 + item.depreciation.shift();
                    }
                }
            });
            lines_depreciation_factory3.map(item => {
                if(Object.keys(item).length !== 0){
                    if(item.state === 'complete' && item.depreciation.length > 1){
                        depreciation_factory3 = depreciation_factory3 + item.depreciation.shift();
                    }
                }
            });

            //repay long loan
            let longloan = state.LongTermLoan;
            let repaylongloan = 0;
            let interest_longloan = 0;
            longloan.map(item => {
                if(item.repay.length === 1){
                    let interest_longloan_Final = item.repay.shift();
                    repaylongloan = repaylongloan + interest_longloan_Final;
                    interest_longloan = interest_longloan + (interest_longloan_Final - item.amount);
                }
                else{
                    //interest for each year before final
                    let interest_longloan_beforeFinal = item.repay.shift();
                    repaylongloan = repaylongloan + interest_longloan_beforeFinal;
                    interest_longloan = interest_longloan + interest_longloan_beforeFinal;
                }
            });
            let newlongLoan = longloan.filter(item => item.repay.length >= 1);


            //income tax
            let comprehensivecost_incomeTax = state.ManagementFee + state.MaintainFee + state.Rent.factory
                + state.Rent.store + state.DevelopFee + state.ChangeCost;
            let income_incomeTax = state.Income;
            let cost_incomeTax = state.Cost;
            let EarningsbeforeTax_incomeTax = income_incomeTax - cost_incomeTax -comprehensivecost_incomeTax - state.Lines_depreciation - state.FinancialCost;
            let incomeTax = 0;
            if(EarningsbeforeTax_incomeTax > 0)
                incomeTax = Number((EarningsbeforeTax_incomeTax * 0.25).toFixed(0));
            else
                incomeTax = 0;


            return {
                ...state,
                ManagementFee: 0,
                DevelopFee: 0,
                ChangeCost: 0,
                Lines_depreciation: depreciation_factory1 + depreciation_factory2 + depreciation_factory3,

                //update longtermloan with FinancialCost
                //treat rent at quarter 4 for next year
                LongTermLoan: newlongLoan,
                Cash: state.Cash - repaylongloan - incomeTax - state.Rent.factory - state.Rent.store,
                FinancialCost: interest_longloan,
                Income: 0,
                Cost: 0
            };
        case 'FORCE4QUARTER':
            //强制跳转到第四季度
            return {
                ...state,
                Quarter: 4
            }

        case 'RESET':
            return initalstate;
        default:
            return state;
    }
}

export default financeReducer;
