import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    loanandreceivable: {
        display: 'flex',
        height: '232px',
        width: '100%',
        justifyContent: 'center'
    }
}));
