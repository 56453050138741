import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import io from 'socket.io-client';

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com';
let socket;

export default function Nextround(props) {
    const dispatch = useDispatch();

    const [disable_nextyear, setDisable_nextyear] = useState(true);

    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);

    useEffect(() =>{
        if(props.cannextyear)
            setDisable_nextyear(false);
    },[props.cannextyear]);

    const quarter = useSelector(state => state.game.Quarter);

    const handleNext = () => {
        if(quarter === 3){
            dispatch({type: 'NEXTROUND'});
            setDisable_nextyear(true);
        }
        else
            dispatch({type: 'NEXTROUND'});
    };

    useEffect(() => {
        socket.on('LOADORDERS', (orders) =>{
            console.log('loadorders', orders);
            dispatch({type: 'LOADORDER', payload: {
                order: orders
                }});
        })
    },[]);

    const handleNextYear = () => {
        //load orders from the room in server
        socket.emit('LOADORDERS', props.name, props.room);

        dispatch({type: 'NEWYEAR'});

        dispatch({type: 'NEXTROUND'});
    }

    return (
        <div>
            {
                quarter === 4
                    ? <Button variant='contained' color='primary'
                              disabled={disable_nextyear}
                              onClick={handleNextYear}>下一年</Button>
                    : <Button variant='contained' color='primary' onClick={handleNext}>下一季度</Button>
            }
        </div>
    );
}
