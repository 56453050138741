import { combineReducers } from 'redux';

import auth from './auth';
import finance from "./finance";

export const allReducers = combineReducers({
    account: auth,
    game: finance
});

