import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    menu: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px'
    },
    input: {
        marginTop: '5px',
    },
    button: {
        marginTop: '5px'
    },
    author: {
        marginTop: '5px',
        marginLeft: '10px'
    }
}));
