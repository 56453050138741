import {Paper, TableBody, TableCell, TableContainer, TableHead} from "@material-ui/core";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import {TableRow, Table} from "@material-ui/core";

const Rule = () => {
    const classes = useStyles();

    return (
        <div style={{marginLeft: 5}}>
            <Paper>
                <div className={classes.rule}>
                    <Typography variant='subtitle1'>1.融资</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>贷款</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>贷款类型</TableCell>
                                    <TableCell align='center'>贷款时间</TableCell>
                                    <TableCell align='center'>贷款额度</TableCell>
                                    <TableCell align='center'>年利息</TableCell>
                                    <TableCell align='center'>还款方式</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>长期贷款</TableCell>
                                    <TableCell align='center'>每年年初</TableCell>
                                    <TableCell align='center' rowSpan={2}>
                                        所有长贷和短贷只和不能超过上年权益的3倍
                                    </TableCell>
                                    <TableCell align='center'>10%</TableCell>
                                    <TableCell align='center'>年初付息，到期还本</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>短期贷款</TableCell>
                                    <TableCell align='center'>每季度初</TableCell>
                                    <TableCell align='center'>5%</TableCell>
                                    <TableCell align='center'>到期一次还本付息</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>资金贴现</TableCell>
                                    <TableCell align='center'>任何时候</TableCell>
                                    <TableCell align='center'>视应收帐款</TableCell>
                                    <TableCell align='center'>10%(1,2季) 12.5(3,4季)</TableCell>
                                    <TableCell align='center'>分季度计算贴息</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>库存拍卖</TableCell>
                                    <TableCell align='center' colSpan={4}>原价卖出</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>2.厂房</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>厂房</TableCell>
                                    <TableCell align='center'>买价</TableCell>
                                    <TableCell align='center'>租金</TableCell>
                                    <TableCell align='center'>售价</TableCell>
                                    <TableCell align='center'>容量</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>大厂房</TableCell>
                                    <TableCell align='center'>440w</TableCell>
                                    <TableCell align='center'>44w/年</TableCell>
                                    <TableCell align='center'>440w</TableCell>
                                    <TableCell align='center'>4</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>中厂房</TableCell>
                                    <TableCell align='center'>300w</TableCell>
                                    <TableCell align='center'>30w/年</TableCell>
                                    <TableCell align='center'>300w</TableCell>
                                    <TableCell align='center'>3</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>小厂房</TableCell>
                                    <TableCell align='center'>180w</TableCell>
                                    <TableCell align='center'>18w/年</TableCell>
                                    <TableCell align='center'>180w</TableCell>
                                    <TableCell align='center'>2</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>3.生产线</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>生产线</TableCell>
                                    <TableCell align='center'>购置费</TableCell>
                                    <TableCell align='center'>安装周期</TableCell>
                                    <TableCell align='center'>生产周期</TableCell>
                                    <TableCell align='center'>转产费</TableCell>
                                    <TableCell align='center'>维护费</TableCell>
                                    <TableCell align='center'>残值</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>手工线</TableCell>
                                    <TableCell align='center'>35w</TableCell>
                                    <TableCell align='center'>0</TableCell>
                                    <TableCell align='center'>2季度</TableCell>
                                    <TableCell align='center'>0</TableCell>
                                    <TableCell align='center'>10w/年</TableCell>
                                    <TableCell align='center'>5</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>自动线</TableCell>
                                    <TableCell align='center'>150w</TableCell>
                                    <TableCell align='center'>3季度</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                    <TableCell align='center'>20</TableCell>
                                    <TableCell align='center'>20w/年</TableCell>
                                    <TableCell align='center'>30</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>柔性线</TableCell>
                                    <TableCell align='center'>200w</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                    <TableCell align='center'>0</TableCell>
                                    <TableCell align='center'>30w/年</TableCell>
                                    <TableCell align='center'>40</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>4.仓库</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>仓库</TableCell>
                                    <TableCell align='center'>买价</TableCell>
                                    <TableCell align='center'>租金</TableCell>
                                    <TableCell align='center'>售价</TableCell>
                                    <TableCell align='center'>空间</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>大仓库</TableCell>
                                    <TableCell align='center'>300w</TableCell>
                                    <TableCell align='center'>30w/年</TableCell>
                                    <TableCell align='center'>300w</TableCell>
                                    <TableCell align='center'>40</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>中仓库</TableCell>
                                    <TableCell align='center'>200w</TableCell>
                                    <TableCell align='center'>20w/年</TableCell>
                                    <TableCell align='center'>200w</TableCell>
                                    <TableCell align='center'>20</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>小仓库</TableCell>
                                    <TableCell align='center'>100w</TableCell>
                                    <TableCell align='center'>10w/年</TableCell>
                                    <TableCell align='center'>100w</TableCell>
                                    <TableCell align='center'>10</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>5.原材料</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>名称</TableCell>
                                    <TableCell align='center'>价格</TableCell>
                                    <TableCell align='center'>运输时间</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>R1</TableCell>
                                    <TableCell align='center'>10w/个</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>R2</TableCell>
                                    <TableCell align='center'>10w/个</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>R3</TableCell>
                                    <TableCell align='center'>10w/个</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>R4</TableCell>
                                    <TableCell align='center'>10w/个</TableCell>
                                    <TableCell align='center'>1季度</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>6.产品</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>名称</TableCell>
                                    <TableCell align='center'>开发费用</TableCell>
                                    <TableCell align='center'>开发周期</TableCell>
                                    <TableCell align='center'>开发总额</TableCell>
                                    <TableCell align='center'>加工费</TableCell>
                                    <TableCell align='center'>原材料</TableCell>
                                    <TableCell align='center'>直接成本</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>P1</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>2季度</TableCell>
                                    <TableCell align='center'>20w</TableCell>
                                    <TableCell align='center'>10w</TableCell>
                                    <TableCell align='center'>R1</TableCell>
                                    <TableCell align='center'>20w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>P2</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>3季度</TableCell>
                                    <TableCell align='center'>30w</TableCell>
                                    <TableCell align='center'>10w</TableCell>
                                    <TableCell align='center'>R2,R3</TableCell>
                                    <TableCell align='center'>30w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>P3</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                    <TableCell align='center'>10w</TableCell>
                                    <TableCell align='center'>R3,R4</TableCell>
                                    <TableCell align='center'>30w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>P4</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>5季度</TableCell>
                                    <TableCell align='center'>50w</TableCell>
                                    <TableCell align='center'>10w</TableCell>
                                    <TableCell align='center'>P1,R2,R3</TableCell>
                                    <TableCell align='center'>50w</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>7.ISO资格认证</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>ISO类型</TableCell>
                                    <TableCell align='center'>每季度研发费用</TableCell>
                                    <TableCell align='center'>研发时限</TableCell>
                                    <TableCell align='center'>研发费用</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>ISO9000</TableCell>
                                    <TableCell align='center'>20w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>80w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>ISO14000</TableCell>
                                    <TableCell align='center'>20w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>80w</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>8.市场开拓</Typography>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>市场</TableCell>
                                    <TableCell align='center'>每季度开拓费用</TableCell>
                                    <TableCell align='center'>开拓时限</TableCell>
                                    <TableCell align='center'>开拓费用</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'>localMarket</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>areaMarket</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>domesticMarket</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>continentalMarket</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center'>internationalMarket</TableCell>
                                    <TableCell align='center'>10w/季度</TableCell>
                                    <TableCell align='center'>4季度</TableCell>
                                    <TableCell align='center'>40w</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.rule}>
                    <Typography variant='subtitle1' style={{marginTop: 10}}>9.选单规则</Typography>
                    <Typography variant='body2'>
                        英式拍买:规定时间内售价最低得单(相同价格，账期长的得单)
                    </Typography>
                </div>
            </Paper>
        </div>
    )
}

export default Rule;
