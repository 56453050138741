import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    time_button: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    finance: {
        border: '1px solid black',
        borderRadius: '5px',
        padding: '5px',
        display: 'grid',
        marginTop: '5px',
        height: '427px',
        gridTemplateAreas: "'header header header'"
            + "'information information information'"
            + "'balance balance balance'"
    },
    header: {
        gridArea: 'header',
        display: 'flex',
        justifyContent: 'space-around'
    },
    information: {
        gridArea: 'information',
        display: 'flex',
        justifyContent: 'center',
    },
    balance: {
        gridArea: 'balance',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px'
    }
}));
