import React, {useEffect, useState} from "react";
import {
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton
} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import {io}  from 'socket.io-client';
import {useHistory} from "react-router-dom";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com';
let socket;

const Rooms = () => {
    const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem('profile')).result.name);

    const [rooms, setRooms] = useState([]);

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    useEffect(() => {
        socket = io(ENDPOINT);

        socket.emit('getAllRooms');
    }, []);
    useEffect(() => {
        socket.on('getAllRooms', arg => {
            setRooms(arg);
        })
    },[]);
    const refreshRooms = () => {
        socket.emit('getAllRooms');
    };

    useEffect(() => {
        socket.on('CanJoin', (n, r, arg) => {
            if(arg){
                socket.off();
                socket.disconnect();
                history.push(`/game?name=${n}&room=${r}`);
            };
        });
    },[]);

    const [Snackbar_join, setSnackbar_join] = useState(false);
    const [str_alert, setStr_alert] = useState('');
    const handleClose_Snackbar = () => {
        setSnackbar_join(false);
    }

    const Join =(e, room) => {
        e.preventDefault();
        // if(!name)
        //     alert('name is null')
        // else
        //     socket.emit('Join', name, room, password, (response) =>{
        //         alert(response);
        // });
        if(!name){
            setSnackbar_join(true);
            setStr_alert('name is null');
        }
        else
            socket.emit('Join', name, room, password, (response) =>{
                if(response !== ''){
                    setSnackbar_join(true);
                    setStr_alert(response);
                }
            });
    }

    const Close_admin = (e, room_index) => {
        e.preventDefault();
        socket.emit('Close_admin', room_index);
        socket.emit('getAllRooms');
    }

    return (
        <div style={{marginLeft: 5}}>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={Snackbar_join} autoHideDuration={2000} onClose={handleClose_Snackbar}>
                <Alert variant='filled' severity='warning'>
                    {str_alert}
                </Alert>
            </Snackbar>
            <TableContainer component={Paper}>
                <Table aria-label='table_room' size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' colSpan={7}>大厅</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='center' style={{width: 100}}>房间名</TableCell>
                            <TableCell align='center' style={{width: 90}}>人数</TableCell>
                            <TableCell align='center' style={{width: 340}}>备注</TableCell>
                            <TableCell align='center' style={{width: 110}}>房间状态</TableCell>
                            <TableCell align='center'>昵称</TableCell>
                            <TableCell align='center'>密码</TableCell>
                            <TableCell align='center'>
                                <IconButton aria-label='refresh'
                                            color='primary'
                                            onClick={refreshRooms}
                                            size='small'>
                                    <RefreshIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rooms.map((room, index) => (
                            <TableRow key={index}>
                                <TableCell align='center'>{room.Room}</TableCell>
                                <TableCell align='center'>{room.Players.length}/{room.Maximum}</TableCell>
                                <TableCell align='center'>{room.Description}</TableCell>
                                <TableCell align='center'>{room.isPlaying ? '正在游戏' : '等待'}</TableCell>
                                <TableCell align='center'>
                                    <TextField label='Name'
                                               id='Name'
                                               variant='outlined'
                                               size='small'
                                               style={{width: 100}}
                                               onChange={(event) => setName(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <TextField label='Password(optional)'
                                               id='password'
                                               variant='outlined'
                                               size='small'
                                               style={{width: 170}}
                                               onChange={(event) => setPassword(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    {admin.match('admin_') !== null
                                        ? <Button variant='outlined' onClick={e => Close_admin(e, index)}>关闭</Button>
                                        : <Button onClick={e => Join(e, room.Room)}
                                                  disabled={room.isPlaying || room.Players.length === room.Maximum}>加入</Button>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Rooms;
