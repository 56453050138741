import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    productline: {
        height: '110px',
        width: '180px',
        border: '1px solid black',
        borderRadius: '5px',
        margin: '5px',
        display: 'grid',
        gridTemplateAreas: "'product line'" +
            "'term term'" +
            "'button button'"
    },
    product: {
        gridArea: 'product',
        margin: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        gridArea: 'line',
        margin: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    term: {
        gridArea: 'term',
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        gridArea: 'button',
        margin: '2px',
        display: 'flex',
        justifyContent: 'space-around'
    },
    empty: {
        height: '110px',
        width: '180px',
        border: '1px solid black',
        borderRadius: '5px',
        margin: '5px'
    },
    factory: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    store: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '2px'
    },
    stock: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '5px',
        marginLeft: '10px',
        marginRight: '10px'
    }
}));
