import React, {useEffect, useState} from "react";
import {io} from 'socket.io-client';
import queryString from 'query-string';
import {Button, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import {Dialog, DialogContent, DialogActions} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Workstation from "./components/Workstation/Workstation";
import Controlmenu from "./components/Controlmenu/Controlmenu";
import FinancialStatement from "./components/FinancialStatement/FinancialStatement";
import Auctions from "./components/Auctions/Auctions";
import {getOrders} from "../../Orders/Loadorder";

// const ENDPOINT = 'http://localhost:8000';
const ENDPOINT = 'https://hermestest-application.herokuapp.com';
let socket;

const Game = ({location}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');

    const [host, setHost] = useState('');
    const [players, setPlayers] =useState([]);
    const [max, setMax] = useState(0);
    const [years, setYears] = useState(1);
    const [timer, setTimer] = useState({});
    const [orderTime, setOrderTime] = useState(false);
    const [bidOrder, setBidOrder] = useState([]);

    const [disable_start, setDisable_start] = useState(false);
    const [open, setOpen] = useState(true);

    const quarter = useSelector(state => state.game.Quarter);

    const history = useHistory();

    const dispatch = useDispatch();

    const [gameend, setGameend] = useState(false);

    //load io connection
    useEffect(() => {
        socket = io(ENDPOINT);
    }, []);


    useEffect(() => {
        const { name, room} = queryString.parse(location.search);

        setRoom(room);
        setName(name);

        if(name && room)
            socket.emit('check_inRoom', name, room);
        else
            history.push(`/home`)
        socket.on('check_inRoom', arg => {
            if(!arg){
                history.push(`/home`);
            }
        })

        socket.on('LoadRoom', arg =>{
            setHost(arg.Host);
            setPlayers(arg.Players);
            setYears(arg.Years);
            setOpen(!arg.isPlaying);
            setMax(arg.Maximum);
            setTimer(arg.Timer);
            setOrderTime(arg.isOrderTime);
            // update bid order
            setBidOrder(arg.Orders);
            setGameend(arg.Gameend);
            if(arg.Host === name)
                setDisable_start(true);
            else
                setDisable_start(false);
        });

    }, [ENDPOINT, location.search]);

    useEffect(() => {
        const { name, room } = queryString.parse(location.search);
        const interval = setInterval(() => {
            if(name && room)
                socket.emit('LoadRoom', room);
        },1000);

        return () =>clearInterval(interval);
    },[]);

    const handleGamestart = () => {
        socket.emit('gamestart', room);
    }

    const handlereset = () => {
        dispatch({type: 'RESET'});
    }


    //如果玩家每回合超时 强制跳转到第四季度并结束
    useEffect(() => {
        if(orderTime && quarter < 4){
            dispatch({type: 'NEXTROUND'})
            dispatch({type: 'FORCE4QUARTER'});
        }
    },[orderTime])

    //check islogin
    if(!user)
        history.push(`/`);

    const cash = useSelector(state => state.game.Cash);

    const handleLeave = () => {
        socket.emit('LEAVE', name, room);
        dispatch({type: 'RESET'});
        history.push(`/home`);
    }

    //load order from mongoDB server
    const [orders_year1, setOrders_year1] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });
    const [orders_year2, setOrders_year2] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });
    const [orders_year3, setOrders_year3] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });
    const [orders_year4, setOrders_year4] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });
    const [orders_year5, setOrders_year5] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });
    const [orders_year6, setOrders_year6] = useState({
        P1: [],
        P2: [],
        P3: [],
        P4: []
    });

    const LoadOrders = async () => {
        let res = await getOrders();
        if( res !== null){

            //year1 orders
            const P1_year1 = res.filter(item => item.product === 'P1' && item.year === 1);
            const P2_year1 = res.filter(item => item.product === 'P2' && item.year === 1);
            const P3_year1 = res.filter(item => item.product === 'P3' && item.year === 1);
            const P4_year1 = res.filter(item => item.product === 'P4' && item.year === 1);
            const Object_year1_orders = {
                P1: P1_year1,
                P2: P2_year1,
                P3: P3_year1,
                P4: P4_year1
            };
            setOrders_year1(Object_year1_orders);

            //year2 orders
            const P1_year2 = res.filter(item => item.product === 'P1' && item.year === 2);
            const P2_year2 = res.filter(item => item.product === 'P2' && item.year === 2);
            const P3_year2 = res.filter(item => item.product === 'P3' && item.year === 2);
            const P4_year2 = res.filter(item => item.product === 'P4' && item.year === 2);
            const Object_year2_orders = {
                P1: P1_year2,
                P2: P2_year2,
                P3: P3_year2,
                P4: P4_year2
            };
            setOrders_year2(Object_year2_orders);

            //year3 orders
            const P1_year3 = res.filter(item => item.product === 'P1' && item.year === 3);
            const P2_year3 = res.filter(item => item.product === 'P2' && item.year === 3);
            const P3_year3 = res.filter(item => item.product === 'P3' && item.year === 3);
            const P4_year3 = res.filter(item => item.product === 'P4' && item.year === 3);
            const Object_year3_orders = {
                P1: P1_year3,
                P2: P2_year3,
                P3: P3_year3,
                P4: P4_year3
            };
            setOrders_year3(Object_year3_orders);

            //year4 orders
            const P1_year4 = res.filter(item => item.product === 'P1' && item.year === 4);
            const P2_year4 = res.filter(item => item.product === 'P2' && item.year === 4);
            const P3_year4 = res.filter(item => item.product === 'P3' && item.year === 4);
            const P4_year4 = res.filter(item => item.product === 'P4' && item.year === 4);
            const Object_year4_orders = {
                P1: P1_year4,
                P2: P2_year4,
                P3: P3_year4,
                P4: P4_year4
            };
            setOrders_year4(Object_year4_orders);

            //year5 orders
            const P1_year5 = res.filter(item => item.product === 'P1' && item.year === 5);
            const P2_year5 = res.filter(item => item.product === 'P2' && item.year === 5);
            const P3_year5 = res.filter(item => item.product === 'P3' && item.year === 5);
            const P4_year5 = res.filter(item => item.product === 'P4' && item.year === 5);
            const Object_year5_orders = {
                P1: P1_year5,
                P2: P2_year5,
                P3: P3_year5,
                P4: P4_year5
            };
            setOrders_year5(Object_year5_orders);

            //year6 orders
            const P1_year6 = res.filter(item => item.product === 'P1' && item.year === 6);
            const P2_year6 = res.filter(item => item.product === 'P2' && item.year === 6);
            const P3_year6 = res.filter(item => item.product === 'P3' && item.year === 6);
            const P4_year6 = res.filter(item => item.product === 'P4' && item.year === 6);
            const Object_year6_orders = {
                P1: P1_year6,
                P2: P2_year6,
                P3: P3_year6,
                P4: P4_year6
            };
            setOrders_year6(Object_year6_orders);
        }
    };

    // load orders from mongoDB
    useEffect(() => {
        LoadOrders();
    }, []);


    return (
        <div>
            <Dialog open={open} aria-labelledby='dialog-gamestart' fullScreen={false} maxWidth='xs'>
                <DialogTitle id='dialog-title'>等待房主开始</DialogTitle>
                <DialogContent>
                    <DialogContentText id='dialog-description'>
                        <Typography>人数:{players.length}/{max}</Typography>
                        {players.map((item, index) => (
                            <Typography key={index}>玩家:{item.name}</Typography>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {disable_start
                        ? <Button onClick={handleGamestart}>游戏开始</Button>
                        : <Button>离开</Button>}
                </DialogActions>
            </Dialog>
            <Dialog open={orderTime} aria-labelledby='dialog-orderTime' fullScreen={false} maxWidth='xl'>
                <DialogTitle id='dialog-title'>订货会-第{years}年-{timer.minute}:{timer.second}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='dialog-orders'>
                        <Auctions year={years} name={name} room={room} bidorder={bidOrder}
                                  order_year1={orders_year1}
                                  order_year2={orders_year2}
                                  order_year3={orders_year3}
                                  order_year4={orders_year4}
                                  order_year5={orders_year5}
                                  order_year6={orders_year6}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={cash >= 0 ? false : true} aria-labelledby='dialog-gameover' fullScreen={false} maxWidth='xs'>
                <DialogTitle id='dialog-gameover'>游戏结束</DialogTitle>
                <DialogContent>
                   <Typography>
                       您已破产
                   </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>离开</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={gameend} aria-labelledby='dialog-gameend' fullScreen={false} maxWidth='xs'>
                <DialogTitle id='dialog-gameend'>游戏结算</DialogTitle>
                <DialogContent>
                    {players.map((item, index) =>(
                        <div key={index}>玩家{item.name}:得分{item.rights}</div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>离开</Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <Grid item xs={8}>
                    <Workstation/>
                </Grid>
                <Grid item xs={4}>
                    <FinancialStatement time={timer} year={years} name={name} room={room}/>
                    <Controlmenu canNextyear={orderTime} name={name} room={room}/>
                </Grid>
            </Grid>
            <Button onClick={handlereset} disabled={true}>reset app</Button>
        </div>
    )
};

export default Game;
