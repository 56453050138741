import React, {useState} from "react";
import useStyles from './styles';
import Buildline from "./component/Buildline/Buildline";
import Nextround from "./component/Nextround/Nextround";
import MarketandDevelop from "./component/MarketandDevelop/MarketandDevelop";
import OrdersMenu from "./component/OrdersMenu/OrdersMenu";

export default function Controlmenu(props) {
    const classes = useStyles();

    return (
        <div className={classes.menu}>
            <Buildline/>
            <OrdersMenu/>
            <MarketandDevelop/>
            <Nextround cannextyear={props.canNextyear} name={props.name} room={props.room}/>
        </div>
    );
};
