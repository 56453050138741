import React, { useState } from "react";
import useStyles from './styles';
import {useDispatch, useSelector} from "react-redux";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormLabel,
    RadioGroup,
    FormControlLabel, Radio, FormControl
} from "@material-ui/core";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

function ProductionLine(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const factory = useSelector(state => state.game[props.name]);
    const linelist = useSelector(state => state.game[props.name].line);
    const clickonce = useSelector(state => state.game.ClickonceEachTerm.Building_Produce);

    const disable_P1 = useSelector(state => state.game.P1.develop);
    const disable_P2 = useSelector(state => state.game.P2.develop);
    const disable_P3 = useSelector(state => state.game.P3.develop);
    const disable_P4 = useSelector(state => state.game.P4.develop);

    // disable product button for develop state
    const disable_Produce = useSelector(state => state.game);

    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(null);

    const R1_currentstock = useSelector(state => state.game.R1.currentStock);
    const R2_currentstock = useSelector(state => state.game.R2.currentStock);
    const R3_currentstock = useSelector(state => state.game.R3.currentStock);
    const R4_currentstock = useSelector(state => state.game.R4.currentStock);
    const P1_currentstock = useSelector(state => state.game.P1.currentStock);

    const handleClickOpen = (e, i, p) => {
        e.preventDefault();
        setOpen(true);
        setIndex(i);
        setProduct(p);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [product, setProduct] = useState(null);
    const handleChange_product = (event) => {
        setProduct(event.target.value);
    };

    const [Snackbar_line, setSnackbar_line] = useState(false);
    const [alert_line, setAlert_line] = useState('');
    const [alert_severity, setAlert_severity] = useState('success');
    const handleClose_Snackbar = () => {
        setSnackbar_line(false);
    }

    const handleSubscribe = () => {
        setAlert_line('转产');
        setAlert_severity('warning');
        setSnackbar_line(true);
        dispatch({type: 'CHANGEPRODUCT', payload: {
                factory:props.name,
                index: index,
                product:product}});
    };

    const handleProduce = (e, i, t) => {
        e.preventDefault();
        setAlert_line(`生产${t}`);
        setAlert_severity('success');
        setSnackbar_line(true);
        dispatch({type: 'PRODUCE', payload: {
                factory: props.name,
                index: i,
                product_type: t
            }});
    };
    const handleBuilding = (e, i) => {
        e.preventDefault();
        setAlert_line('修建生产线');
        setAlert_severity('success');
        setSnackbar_line(true);
        dispatch({type: 'BUILDING', payload: {
                factory: props.name,
                index: i
            }});
    };
    const handleRemove = (e, i) => {
        e.preventDefault();
        setAlert_line('拆解生产线');
        setAlert_severity('warning');
        setSnackbar_line(true);
        dispatch({type: 'DISASSEMBLE', payload: {
            factory: props.name,
                index: i,
            }})
    };

    const handleChangeFactory = () => {
        dispatch({type: 'CHANGEFACTORY', payload: {
            factory: props.name
            }});
    };

    const product_checkMaterial = (product) => {
        switch (product) {
            case 'P1':
                if(R1_currentstock >0)
                    return true
                else
                    return false
                break;
            case 'P2':
                if(R2_currentstock >0 && R3_currentstock >0)
                    return true
                else
                    return false
                break;
            case 'P3':
                if(R3_currentstock >0 && R4_currentstock >0)
                    return true
                else
                    return false
                break;
            case 'P4':
                if( P1_currentstock > 0 && R2_currentstock >0 && R4_currentstock >0)
                    return true
                else
                    return false
        }
    }

    return (
        <div>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={Snackbar_line} autoHideDuration={1000} onClose={handleClose_Snackbar}>
                <Alert variant='filled' severity={alert_severity}>
                    {alert_line}
                </Alert>
            </Snackbar>
            {linelist.map((item, index) => {
                if(Object.keys(item).length === 0)
                    return (<div className={classes.empty} key={index}></div>)
                else
                    return (
                        <div className={classes.productline} key={index}>
                            <div className={classes.product}>
                                <Button size='small' variant='outlined'
                                        onClick={e => handleClickOpen(e, index, item.producttype)}
                                        disabled={item.progress[0]}>
                                    {item.producttype}
                                </Button>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-changeproduct">
                                    <DialogTitle id="form-dialog-changeproduct">changeproduct</DialogTitle>
                                    <DialogContent>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend'>Product</FormLabel>
                                            <RadioGroup aria-label='product' name='product' value={product} onChange={handleChange_product} row>
                                                <FormControlLabel control={<Radio />} label='P1' value='P1' disabled={!disable_P1}/>
                                                <FormControlLabel control={<Radio />} label='P2' value='P2' disabled={!disable_P2}/>
                                                <FormControlLabel control={<Radio />} label='P3' value='P3' disabled={!disable_P3}/>
                                                <FormControlLabel control={<Radio />} label='P4' value='P4' disabled={!disable_P4}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSubscribe} color="primary">
                                            Subscribe
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div className={classes.line}>{item.linetype}</div>
                            <div className={classes.term}>{item.progress.map((p, index) => {
                                if(item.state == 'complete')
                                    return ( p
                                        ? <CheckBoxRoundedIcon color='primary' fontSize='small' key={index} />
                                        : <CheckBoxRoundedIcon fontSize='small' key={index} />)
                                else
                                    return ( p
                                        ? (<BuildRoundedIcon color="primary" fontSize='small' key={index} />)
                                        : (<BuildRoundedIcon fontSize='small' key={index} />))
                            })}</div>
                            <div className={classes.button}>
                                {item.state === 'complete'
                                    ? <Button variant='outlined'
                                              onClick={e => handleProduce(e, index, item.producttype)}

                                              disabled={Boolean(clickonce.find(item => item.factory === props.name && item.index === index))
                                              || !disable_Produce[item.producttype].develop
                                              || !product_checkMaterial(item.producttype)}>生产</Button>
                                    : <Button variant='outlined' onClick={e => handleBuilding(e, index)} disabled={Boolean(clickonce.find(item => item.factory === props.name && item.index === index))}>建造</Button>}
                                <Button variant='outlined'
                                        onClick={e => handleRemove(e, index)}
                                        disabled={item.state === 'complete' ? false : true}>拆解</Button>
                            </div>
                        </div>
                    )
            })}
            <div className={classes.factory}>
                <Typography>{factory.size}</Typography>
                <Typography>{factory.property}</Typography>
                <Button variant='contained' size='small'
                        disabled={true}
                        onClick={handleChangeFactory}>厂房改造</Button>
            </div>
        </div>
    );
};

export default ProductionLine;
