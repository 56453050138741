import React, {useState} from "react";
import useStyles from "./styles";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Slider,
    TextField,
    Tooltip
} from "@material-ui/core";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function Store(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const cash = useSelector(state => state.game.Cash);
    const finance = useSelector(state => state.game.Finance);

    const R1 = useSelector(state => state.game.R1);
    const R2 = useSelector(state => state.game.R2);
    const R3 = useSelector(state => state.game.R3);
    const R4 = useSelector(state => state.game.R4);
    const P1 = useSelector(state => state.game.P1);
    const P2 = useSelector(state => state.game.P2);
    const P3 = useSelector(state => state.game.P3);
    const P4 = useSelector(state => state.game.P4);
    const store = useSelector(state => state.game.storehouse);

    const [Snackbar_buy, setSnackbar_buy] = useState(false);
    const [alert_buy, setAlert_buy] = useState('');
    const [alert_severity, setAlert_severity] = useState('success');
    const handleClose_Snackbar = () => {
        setSnackbar_buy(false);
    }

    const [r1, setR1] = useState(0);
    const handleSliderR1 = (event, newR1) => {
        setR1(newR1);
    };
    const handleInputR1 = (event) => {
        setR1(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurR1 = () => {
        if(r1 < 0)
            setR1(0);
        else if(r1 > 20)
            setR1(20);
    };
    const BuyR1_Normal = () => {
        if(cash - r1 * finance.R1.normalPrice > 0){
            setAlert_buy(`成功购买${r1}个R1,总价${r1 * finance.R1.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'R1', amount: r1}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyR1_Urgent = () => {
        if(cash - r1 * finance.R1.urgentPrice > 0){
            setAlert_buy(`成功购买${r1}个R1,总价${r1 * finance.R1.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'R1', amount: r1}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [r2, setR2] = useState(0);
    const handleSliderR2 = (event, newR2) => {
        setR2(newR2);
    };
    const handleInputR2 = (event) => {
        setR2(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurR2 = () => {
        if(r2 < 0)
            setR2(0);
        else if(r1 > 20)
            setR2(20);
    };
    const BuyR2_Normal = () => {
        if(cash - r2 * finance.R2.normalPrice > 0){
            setAlert_buy(`成功购买${r2}个R2,总价${r2 * finance.R2.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'R2', amount: r2}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyR2_Urgent = () => {
        if(cash - r2 * finance.R2.urgentPrice > 0){
            setAlert_buy(`成功购买${r2}个R2,总价${r2 * finance.R2.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'R2', amount: r2}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [r3, setR3] = useState(0);
    const handleSliderR3 = (event, newR3) => {
        setR3(newR3);
    };
    const handleInputR3 = (event) => {
        setR3(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurR3 = () => {
        if(r3 < 0)
            setR3(0);
        else if(r3 > 20)
            setR3(20);
    };
    const BuyR3_Normal = () => {
        if(cash - r3 * finance.R3.normalPrice > 0){
            setAlert_buy(`成功购买${r3}个R3,总价${r3 * finance.R3.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'R3', amount: r3}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyR3_Urgent = () => {
        if(cash - r3 * finance.R3.urgentPrice > 0){
            setAlert_buy(`成功购买${r3}个R3,总价${r3 * finance.R3.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'R3', amount: r3}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [r4, setR4] = useState(0);
    const handleSliderR4 = (event, newR4) => {
        setR4(newR4);
    };
    const handleInputR4 = (event) => {
        setR4(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurR4 = () => {
        if(r4 < 0)
            setR4(0);
        else if(r4 > 20)
            setR4(20);
    };
    const BuyR4_Normal = () => {
        if(cash - r4 * finance.R4.normalPrice > 0){
            setAlert_buy(`成功购买${r4}个R4,总价${r4 * finance.R4.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'R4', amount: r4}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyR4_Urgent = () => {
        if(cash - r4 * finance.R4.urgentPrice > 0){
            setAlert_buy(`成功购买${r4}个R4,总价${r4 * finance.R4.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'R4', amount: r4}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [p1, setP1] = useState(0);
    const handleSliderP1 = (event, newP1) => {
        setP1(newP1);
    };
    const handleInputP1 = (event) => {
        setP1(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurP1 = () => {
        if(p1 < 0)
            setP1(0);
        else if(p1 > 20)
            setP1(20);
    };
    const BuyP1_Normal = () => {
        if(cash - p1 * finance.P1.normalPrice > 0){
            setAlert_buy(`成功购买${p1}个P1,总价${p1 * finance.P1.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'P1', amount: p1}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyP1_Urgent = () => {
        if(cash - p1 * finance.P1.urgentPrice > 0){
            setAlert_buy(`成功购买${p1}个P1,总价${p1 * finance.P1.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'P1', amount: p1}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [p2, setP2] = useState(0);
    const handleSliderP2 = (event, newP2) => {
        setP2(newP2);
    };
    const handleInputP2 = (event) => {
        setP2(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurP2 = () => {
        if(p2 < 0)
            setP2(0);
        else if(p2 > 20)
            setP2(20);
    };
    const BuyP2_Normal = () => {
        if(cash - p2 * finance.P2.normalPrice > 0){
            setAlert_buy(`成功购买${p2}个P2,总价${p2 * finance.P2.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'P2', amount: p2}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyP2_Urgent = () => {
        if(cash - p2 * finance.P2.urgentPrice > 0){
            setAlert_buy(`成功购买${p2}个P2,总价${p2 * finance.P2.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'P2', amount: p2}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [p3, setP3] = useState(0);
    const handleSliderP3 = (event, newP3) => {
        setP3(newP3);
    };
    const handleInputP3 = (event) => {
        setP3(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurP3 = () => {
        if(p3 < 0)
            setP3(0);
        else if(p3 > 20)
            setP3(20);
    };
    const BuyP3_Normal = () => {
        if(cash - p3 * finance.P3.normalPrice > 0){
            setAlert_buy(`成功购买${p3}个P3,总价${p3 * finance.P3.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'P3', amount: p3}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyP3_Urgent = () => {
        if(cash - p3 * finance.P3.urgentPrice > 0){
            setAlert_buy(`成功购买${p3}个P3,总价${p3 * finance.P3.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'P3', amount: p3}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const [p4, setP4] = useState(0);
    const handleSliderP4 = (event, newP4) => {
        setP4(newP4);
    };
    const handleInputP4 = (event) => {
        setP4(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlurP4 = () => {
        if(p4 < 0)
            setP4(0);
        else if(p4 > 20)
            setP4(20);
    };
    const BuyP4_Normal = () => {
        if(cash - p4 * finance.P4.normalPrice > 0){
            setAlert_buy(`成功购买${p4}个P4,总价${p4 * finance.P4.normalPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'normal', kind: 'P4', amount: p4}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };
    const BuyP4_Urgent = () => {
        if(cash - p4 * finance.P4.urgentPrice > 0){
            setAlert_buy(`成功购买${p4}个P4,总价${p4 * finance.P4.urgentPrice}`);
            setAlert_severity('success');
            setSnackbar_buy(true);
            dispatch({type: 'BUY', payload: {condition: 'urgent', kind: 'P4', amount: p4}});
        }
        else{
            setAlert_buy('现金不足');
            setAlert_severity('warning');
            setSnackbar_buy(true);
        }
    };

    const R1_clear = () => {
        setAlert_buy('R1库存数-1');
        setAlert_severity('warning');
        setSnackbar_buy(true);
        dispatch({type: 'CLEAR', payload: {product: 'R1'}})
    };
    const R2_clear = () => {
        setAlert_buy('R2库存数-1');
        setAlert_severity('warning');
        setSnackbar_buy(true);
        dispatch({type: 'CLEAR', payload: {product: 'R2'}})
    }
    const R3_clear = () => {
        setAlert_buy('R3库存数-1');
        setAlert_severity('warning');
        setSnackbar_buy(true);
        dispatch({type: 'CLEAR', payload: {product: 'R3'}})
    }
    const R4_clear = () => {
        setAlert_buy('R4库存数-1');
        setAlert_severity('warning');
        setSnackbar_buy(true);
        dispatch({type: 'CLEAR', payload: {product: 'R4'}})
    }

    const buy_space = store.storage - r1 - r2 - r3 - r4 - p1 - p2 - p3 - p4
        - R1.currentStock - R2.currentStock - R3.currentStock - R4.currentStock
        - P1.currentStock - P2.currentStock - P3.currentStock - P4.currentStock
        - R1.transit - R2.transit - R3.transit - R4.transit
        - P1.transit - P2.transit - P3.transit - P4.transit;

    //change store
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick_changeStore = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose_changeStore = () => {
        setAnchorEl(null);
    };

    const [Snackbar_changeStore, setSnackbar_changeStore] = useState(false);
    const [alert_changeStore, setAlert_changeStore] = useState('');
    const [alert_changeStore_severity, setAlert_changeStore_severity] = useState('success');
    const handleClose_Snackbar_changeStore = () => {
        setSnackbar_changeStore(false);
    }

    const current_space = R1.currentStock + R2.currentStock + R3.currentStock + R4.currentStock
        + P1.currentStock + P2.currentStock + P3.currentStock + P4.currentStock
        + R1.transit + R2.transit + R3.transit + R4.transit
        + P1.transit + P2.transit + P3.transit + P4.transit;

    const change_rentSmall = () => {
        if(finance.Store.small.space >= current_space){
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'rent',
                    size: 'small',
                }});
        }
        else{
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
    };
    const change_rentMiddle = () => {
        if(finance.Store.middle.space >= current_space){
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'rent',
                    size: 'middle',
                }});
        }
        else{
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
    };
    const change_rentLarge = () => {
        if(finance.Store.large.space >= current_space){
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'rent',
                    size: 'large',
                }});
        }
        else{
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
    };
    const change_buySmall = () => {
        if(finance.Store.small.space < current_space){
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
        else{
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'buy',
                    size: 'small',
                }});
        }
    };
    const change_buyMiddle = () => {
        if(finance.Store.middle.space < current_space){
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
        else{
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'buy',
                    size: 'middle',
                }});
        }
    };
    const change_buyLarge = () => {
        if(finance.Store.large.space < current_space){
            setAlert_changeStore('空间不足');
            setAlert_changeStore_severity('warning');
            setSnackbar_changeStore(true);
        }
        else{
            setAnchorEl(null);
            dispatch({type: 'changeStore', payload: {
                    property: 'buy',
                    size: 'large',
                }});
        }
    };


    return (
        <div className={classes.store}>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={Snackbar_buy} autoHideDuration={2000} onClose={handleClose_Snackbar}>
                <Alert variant='filled' severity={alert_severity}>
                    {alert_buy}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={Snackbar_changeStore} autoHideDuration={2000} onClose={handleClose_Snackbar_changeStore}>
                <Alert variant='filled' severity={alert_changeStore_severity}>
                    {alert_changeStore}
                </Alert>
            </Snackbar>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Property:{store.property}/{store.size}</Typography>
            </div>
            <div>
                <Typography style={{display: 'flex', justifyContent: 'center'}}>仓储空间:{R1.currentStock
                +R2.currentStock
                +R3.currentStock
                +R4.currentStock
                +P1.currentStock
                +P2.currentStock
                +P3.currentStock
                +P4.currentStock}/{store.storage}</Typography>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + R1.transit + '个'} arrow>
                    <Typography>R1: {R1.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'库存-1' + '(+' + finance.R1.normalPrice + 'W)'} arrow>
                    <Button variant='outlined' color='secondary' size='small'
                            onClick={R1_clear} disabled={!R1.currentStock>0}>库存清理</Button>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + R2.transit + '个'} arrow>
                    <Typography>R2: {R2.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'库存-1' + '(+' + finance.R2.normalPrice + 'W)'} arrow>
                    <Button variant='outlined' color='secondary' size='small'
                            onClick={R2_clear} disabled={!R2.currentStock>0}>库存清理</Button>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + R3.transit + '个'} arrow>
                    <Typography>R3: {R3.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'库存-1' + '(+' + finance.R3.normalPrice + 'W)'} arrow>
                    <Button variant='outlined' color='secondary' size='small'
                            onClick={R3_clear} disabled={!R3.currentStock>0}>库存清理</Button>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + R4.transit + '个'} arrow>
                    <Typography>R4: {R4.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'库存-1' + '(+' + finance.R4.normalPrice + 'W)'} arrow>
                    <Button variant='outlined' color='secondary' size='small'
                            onClick={R4_clear} disabled={!R4.currentStock>0}>库存清理</Button>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + P1.transit + '个'} arrow>
                    <Typography>P1: {P1.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'下季度预计到达：' + P2.transit + '个'} arrow>
                    <Typography>P2: {P2.currentStock}</Typography>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <Tooltip title={'下季度预计到达：' + P3.transit + '个'} arrow>
                    <Typography>P3: {P3.currentStock}</Typography>
                </Tooltip>
                <Tooltip title={'下季度预计到达：' + P4.transit + '个'} arrow>
                    <Typography>P4: {P4.currentStock}</Typography>
                </Tooltip>
            </div>
            <div className={classes.stock}>
                <div>
                    <Button variant='contained' color='primary' size='small'
                            onClick={handleClickOpen}
                            disabled={R1.currentStock
                            +R2.currentStock
                            +R3.currentStock
                            +R4.currentStock
                            +P1.currentStock
                            +P2.currentStock
                            +P3.currentStock
                            +P4.currentStock <= store.storage ? false : true}>购买原材料</Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-buymaterial" fullWidth>
                        <DialogTitle id="form-dialog-buymaterial">购买原材料</DialogTitle>
                        <DialogContent>
                            <FormControl component='fieldset'>
                                <FormGroup>
                                    {/*-----R1-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof r1 === 'number' ? r1 : 0}
                                                    onChange={handleSliderR1}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-R1'/>
                                            <TextField id='R1-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={r1}
                                                       onChange={handleInputR1}
                                                       onBlur={handleBlurR1}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-R1'}}/>
                                            <Tooltip title={'单价' + finance.R1.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyR1_Normal} disabled={buy_space >= 0 ? false : true}>购买({r1*finance.R1.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.R1.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyR1_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.R1.urgentPrice*r1}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='R1'
                                                      labelPlacement='start'/>
                                    {/*-----R2-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof r2 === 'number' ? r2 : 0}
                                                    onChange={handleSliderR2}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-R2'/>
                                            <TextField id='R2-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={r2}
                                                       onChange={handleInputR2}
                                                       onBlur={handleBlurR2}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-R2'}}/>
                                            <Tooltip title={'单价' + finance.R2.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyR2_Normal} disabled={buy_space >= 0 ? false : true}>购买({r2*finance.R2.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.R2.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyR2_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.R2.urgentPrice*r2}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='R2'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----R3-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof r3 === 'number' ? r3 : 0}
                                                    onChange={handleSliderR3}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-R3'/>
                                            <TextField id='R3-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={r3}
                                                       onChange={handleInputR3}
                                                       onBlur={handleBlurR3}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-R3'}}/>
                                            <Tooltip title={'单价' + finance.R3.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyR3_Normal} disabled={buy_space >= 0 ? false : true}>购买({r3*finance.R3.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.R3.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyR3_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.R3.urgentPrice*r3}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='R3'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----R4-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof r4 === 'number' ? r4 : 0}
                                                    onChange={handleSliderR4}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-R4'/>
                                            <TextField id='R4-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={r4}
                                                       onChange={handleInputR4}
                                                       onBlur={handleBlurR4}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-R4'}}/>
                                            <Tooltip title={'单价' + finance.R4.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyR4_Normal} disabled={buy_space >= 0 ? false : true}>购买({r4*finance.R4.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.R4.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyR4_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.R4.urgentPrice*r4}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='R4'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----P1-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof p1 === 'number' ? p1 : 0}
                                                    onChange={handleSliderP1}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-P1'/>
                                            <TextField id='P1-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={p1}
                                                       onChange={handleInputP1}
                                                       onBlur={handleBlurP1}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-P1'}}/>
                                            <Tooltip title={'单价' + finance.P1.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyP1_Normal} disabled={buy_space >= 0 ? false : true}>购买({p1*finance.P1.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.P1.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyP1_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.P1.urgentPrice*p1}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='P1'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----P2-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof p2 === 'number' ? p2 : 0}
                                                    onChange={handleSliderP2}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-P2'/>
                                            <TextField id='P2-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={p2}
                                                       onChange={handleInputP2}
                                                       onBlur={handleBlurP2}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-P2'}}/>
                                            <Tooltip title={'单价' + finance.P2.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyP2_Normal} disabled={buy_space >= 0 ? false : true}>购买({p2*finance.P2.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.P2.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyP2_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.P2.urgentPrice*p2}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='P2'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----P3-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof p3 === 'number' ? p3 : 0}
                                                    onChange={handleSliderP3}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-P3'/>
                                            <TextField id='P3-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={p3}
                                                       onChange={handleInputP3}
                                                       onBlur={handleBlurP3}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-P3'}}/>
                                            <Tooltip title={'单价' + finance.P3.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyP3_Normal} disabled={buy_space >= 0 ? false : true}>购买({p3*finance.P3.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.P3.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyP3_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.P3.urgentPrice*p3}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='P3'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                    {/*-----P4-----------*/}
                                    <FormControlLabel control={
                                        <div style={{display: 'flex', width: '500px', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <Slider style={{width: '120px'}}
                                                    value={typeof p4 === 'number' ? p4 : 0}
                                                    onChange={handleSliderP4}
                                                    step={2}
                                                    max={20}
                                                    aria-labelledby='input-P4'/>
                                            <TextField id='P4-number' type='number'
                                                       label='数量'
                                                       InputLabelProps={{shrink: true}}
                                                       variant='outlined' size='small'
                                                       style={{width: '70px'}}
                                                       value={p4}
                                                       onChange={handleInputP4}
                                                       onBlur={handleBlurP4}
                                                       inputProps={{min: 0, max: 20, 'aria-labelledby': 'input-P4'}}/>
                                            <Tooltip title={'单价' + finance.P4.normalPrice + 'W'} arrow>
                                                <Button variant='contained' color='primary' onClick={BuyP4_Normal} disabled={buy_space >= 0 ? false : true}>购买({p4*finance.P4.normalPrice}W)</Button>
                                            </Tooltip>
                                            <Tooltip title={ '紧急采购价' + finance.P4.urgentPrice + 'W'} arrow>
                                                <Button variant='contained' color='secondary' onClick={BuyP4_Urgent} disabled={buy_space >= 0 ? false : true}>紧急采购({finance.P4.urgentPrice*p4}W)</Button>
                                            </Tooltip>
                                        </div>}
                                                      label='P4'
                                                      labelPlacement='start'
                                                      style={{marginTop: '10px'}}/>
                                </FormGroup>
                            </FormControl>
                        </DialogContent>
                    </Dialog>
                </div>
                <Button color='primary' variant='contained' size='small'
                        aria-controls='simple-menu' aria-haspopup='true'
                        onClick={handleClick_changeStore}
                        disabled={props.quarter === 4 ? false : true}>仓库变更</Button>
                <Menu
                    id='changeStore'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose_changeStore}
                >
                    <MenuItem
                        onClick={change_rentSmall}
                        disabled={store.property === 'rent' && store.size === 'small' ? true : false}>租用小仓库(租金10/年)</MenuItem>
                    <MenuItem
                        onClick={change_rentMiddle}
                        disabled={store.property === 'rent' && store.size === 'middle' ? true : false}>租用中仓库(租金20/年)</MenuItem>
                    <MenuItem
                        onClick={change_rentLarge}
                        disabled={store.property === 'rent' && store.size === 'large' ? true : false}>租用大仓库(租金30/年)</MenuItem>
                    <MenuItem
                        onClick={change_buySmall}
                        disabled={store.property === 'buy' && store.size === 'small' ? true : false}>购买小仓库(金额:100)</MenuItem>
                    <MenuItem
                        onClick={change_buyMiddle}
                        disabled={store.property === 'buy' && store.size === 'middle' ? true : false}>购买中仓库(金额:200)</MenuItem>
                    <MenuItem
                        onClick={change_buyLarge}
                        disabled={store.property === 'buy' && store.size === 'large' ? true : false}>购买大仓库(金额:300)</MenuItem>
                </Menu>
            </div>
        </div>
    );
}
